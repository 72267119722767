import { useEffect, useState } from "react";
import {
  ImageField,
  TextField,
  BooleanField,
  DateField,
  EmailField,
  FunctionField,
  SelectInput,
  useNotify,
} from "react-admin";
import ReactJson from "react-json-view";
import { useReadData } from "../../api";
import { QueryLinking } from "../../components";
import { ThreeWaysSwith } from "../../components/threeWaysSwitch/ThreeWaysSwith";
import { userLevelChanger } from "./instantUpdates";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const UserDetail = (props) => {
  const { record, mode } = props;
  const notify = useNotify();
  const data = useReadData(
    "Config",
    null,
    null,
    "variable",
    "level-requirements"
  );
  console.log("data", data);
  const [level, setLevel] = useState(record.level || 0);

  const user = data.error ? null : data.data[0];
  return (
    <>
      <ImageField source="avatar._url" />
      <section className="wrapper">
        <div>
          <h1>User Level:</h1>
          {data.loading ? (
            data.error ? (
              "Error while fetching data"
            ) : (
              "Loading...."
            )
          ) : (
            <Select
              value={level}
              onChange={(e) => userLevelChanger(e, notify, record, setLevel)}
            >
              {user?.get("listValue").map((el, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {index}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </div>
      </section>
      <section className="wrapper">
        <div>
          <h1>ICO Main page Access:</h1>
          {mode === "edit" ? (
            <ThreeWaysSwith
              source="icoEnabled"
              parseClass="User"
              record={record}
            />
          ) : (
            <FunctionField
              render={(record) =>
                record.icoEnabled === undefined
                  ? "Based on system config"
                  : record.icoEnabled === true
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>
        <div>
          <h1>ICO Fiat Access:</h1>
          {mode === "edit" ? (
            <ThreeWaysSwith
              source="icoFiatEnabled"
              parseClass="User"
              record={record}
            />
          ) : (
            <FunctionField
              render={(record) =>
                record.icoFiatEnabled === undefined
                  ? "Based on system config"
                  : record.icoFiatEnabled === true
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>
        <div>
          <h1>ICO Swap Access:</h1>
          {mode === "edit" ? (
            <ThreeWaysSwith
              source="icoSwapEnabled"
              parseClass="User"
              record={record}
            />
          ) : (
            <FunctionField
              render={(record) =>
                record.icoSwapEnabled === undefined
                  ? "Based on system config"
                  : record.icoSwapEnabled === true
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>
        <div>
          <h1>ICO Wire Access:</h1>
          {mode === "edit" ? (
            <ThreeWaysSwith
              source="icoWireEnabled"
              parseClass="User"
              record={record}
            />
          ) : (
            <FunctionField
              render={(record) =>
                record.icoWireEnabled === undefined
                  ? "Based on system config"
                  : record.icoWireEnabled === true
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>
        <div>
          <h1>Gaming Access:</h1>
          {mode === "edit" ? (
            <ThreeWaysSwith
              source="gamingEnabled"
              parseClass="User"
              record={record}
            />
          ) : (
            <FunctionField
              render={(record) =>
                record.gamingEnabled === undefined
                  ? "Based on system config"
                  : record.gamingEnabled === true
                  ? "Yes"
                  : "No"
              }
            />
          )}
        </div>
      </section>
      <section className="wrapper">
        <div>
          <h1>Username:</h1>
          <TextField source="username" />
        </div>
        <div>
          <h1>Fullname:</h1>
          <TextField source="name" /> <TextField source="family" />
        </div>
        <div>
          <h1>Country:</h1>
          {mode === "edit" ? (
            <TextField source="country" label="" />
          ) : (
            <TextField source="country" />
          )}
        </div>
        <div>
          <h1>Email Verified:</h1>
          <BooleanField source="emailVerified" />
        </div>
      </section>
      <section className="wrapper">
        <div>
          <h1>Verified at:</h1>
          {mode === "edit" ? (
            <DateField showTime={true} source="verifiedAt" label="" />
          ) : (
            <DateField showTime={true} source="verifiedAt" />
          )}
        </div>
        <div></div>
        <div>
          <h1>KYC at:</h1>
          <DateField showTime={true} source="KYCAt" />
        </div>
        <div>
          <h1>Veriff Session at:</h1>
          <DateField showTime={true} source="veriffSessionAt" />
        </div>
      </section>
      <section className="wrapper">
        <div>
          <h1>Refferrer:</h1>
          <QueryLinking
            subClass="referrer"
            subClassRefrence="referralCode"
            record={record}
            resource="User"
          />
        </div>
        <div>
          <h1>Refferral Code:</h1>
          <TextField source="referralCode" />
        </div>
        <div>
          <h1>Stripe Customer ID:</h1>
          <TextField source="stripeCustomerId" />
        </div>
        <div></div>
      </section>
      <section className="wrapper">
        <div>
          <h1>Veriff Session Status:</h1>
          <TextField source="veriffSessionStatus" />
        </div>
        <div>
          <h1>Veriff Session ID:</h1>
          <TextField source="veriffSessionId" />
        </div>
        <div></div>
        <div>
          <h1>Email:</h1>
          <EmailField source="email" />
        </div>
      </section>
      <section className="wrapper">
        <div>
          <h1>Session Token:</h1>
          <TextField source="sessionToken" />
        </div>
        <div>
          <h1>Created at:</h1>
          <DateField showTime={true} source="createdAt" />
        </div>
        <div>
          <h1>Updated at:</h1>
          <DateField showTime={true} source="updatedAt" />
        </div>
        <div></div>
      </section>
      <section className="wrapper wrapper-pre">
        <h1>Veriff Session:</h1>
        <pre>
          <ReactJson
            src={record.veriffSession}
            displayDataTypes={false}
            quotesOnKeys={false}
          />
        </pre>
        <h1>Platforms:</h1>
        <pre>
          <ReactJson
            src={record.platforms}
            displayDataTypes={false}
            quotesOnKeys={false}
          />
        </pre>
      </section>
    </>
  );
};

export { UserDetail };
