/* eslint-disable eqeqeq */
import React from "react";
import { Link } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Hash } from "../components/Hash";
import { DatePickerTime } from "./DatePickerTime";

const RelationRow = (props) => {
  const {
    data,
    columns,
    title,
    startDate,
    setStartDate,
    finishDate,
    setFinishDate,
  } = props;
  const useStyles = makeStyles({
    root: {
      width: "100%",
      marginTop: title ? "5px" : "70px",
      marginBottom: "25px",
    },
    container: {},
    MuiTableCell: {
      padding: 20,
    },
    customPadding: {
      padding: `6px 24px 6px 16px`,
    },
    center: {
      textAlign: "center",
    },
  });

  const classes = useStyles();

  const determineWinner = (sideScores) => {
    if (sideScores) {
      if (
        sideScores[0] &&
        sideScores[0].score !== undefined &&
        sideScores[1] &&
        sideScores[1].score !== undefined
      ) {
        return sideScores[0].score > sideScores[1].score
          ? `Side ${sideScores[0].side}`
          : sideScores[0].score < sideScores[1].score
          ? `Side ${sideScores[1].side}`
          : "TIE";
      }
    }
  };

  const sideScore = (sideScores) => {
    if (sideScores) {
      if (
        sideScores[0] &&
        sideScores[0].score !== undefined &&
        sideScores[1] &&
        sideScores[1].score !== undefined
      ) {
        return (
          <>
            <span>
              Side {sideScores[0].side == 1 ? "1" : "2"} : {sideScores[0].score}
            </span>
            <br />
            <span>
              Side {sideScores[1].side == 1 ? "1" : "2"} : {sideScores[1].score}
            </span>
            <br />
            <span>Winner : {determineWinner(sideScores)}</span>
          </>
        );
      }
    }
  };

  return (
    <>
      {title ? <h2 className={classes.center}>{title}</h2> : null}
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell className={classes.customPadding} key={column.id}>
                    {" "}
                    {column.label}{" "}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover role="checkbox" tabIndex={-1}>
                {columns.map((column) => {
                  const value = data?.[0].attributes[column.id];
                  return column.editable ? null : (
                    <TableCell
                      key={column.id}
                      className={classes.customPadding}
                    >
                      {/* {column.type === "presetName" ? (
                        data?.[0] ? (
                          <Link
                            to={`/${"CompetitionPreset"}/${
                              data?.[0]?.get("preset")?.id
                            }/show`}
                          >
                            {" "}
                            {data[0]
                              .get("preset")
                              .get("game")
                              .get("title")}{" "}
                            {data[0].get("preset").get("players")}vs
                            {data[0].get("preset").get("players")} $
                            {data?.[0].get("preset").get("feeInUSD")} #
                            {data?.[0].get("preset").get("blockchainID")}
                          </Link>
                        ) : null
                      ) : null} */}
                      {/* {column.type === "presetName" ? (
                        data?.[0]?.get("preset").id ? (
                          <Link
                            to={`/${"CompetitionPreset"}/${
                              data[0].get("preset").id
                            }/show`}
                          >
                            {" "}
                            {data[0]
                              .get("preset")
                              .get("game")
                              .get("title")}{" "}
                            {data[0].get("preset").get("players")}vs
                            {data[0].get("preset").get("players")} $
                            {data?.[0].get("preset").get("feeInUSD")} #
                            {data?.[0].get("preset").get("blockchainID")}
                          </Link>
                        ) : (
                          "N/A"
                        )
                      ) : (
                        ""
                      )} */}
                      {column.id === "preset" ? (
                        data?.[0]?.get("preset")?.id ? (
                          data?.[0]?.get("preset")?.id == undefined ? (
                            "ddd"
                          ) : (
                            <Link
                              to={`/${"CompetitionPreset"}/${
                                data[0].get("preset").id
                              }/show`}
                            >
                              {" "}
                              {data[0]
                                .get("preset")
                                ?.get("game")
                                ?.get("title")}{" "}
                              {data[0]?.get("preset")?.get("players")}vs
                              {data[0]?.get("preset")?.get("players")} $
                              {data?.[0]?.get("preset")?.get("feeInUSD")} #
                              {data?.[0]?.get("preset")?.get("blockchainID")}
                            </Link>
                          )
                        ) : (
                          "N/A"
                        )
                      ) : (
                        ""
                      )}
                      {column.id === "startBlockchainLog" ? (
                          <Link
                          to={`/${"BlockchainLog"}/${
                            data?.[0]?.get("startBlockchainLog")?.id
                          }/show`}
                        >
                          {" "}
                          {data?.[0]?.get("startBlockchainLog")?.id
                          }
                        </Link> 
                      ) : null }

                      {column.id === "finishBlockchainLog" ? (
                          <Link
                          to={`/${"BlockchainLog"}/${
                            data?.[0]?.get("finishBlockchainLog")?.id
                          }/show`}
                        >
                          {" "}
                          {data?.[0]?.get("finishBlockchainLog")?.id
                          }
                        </Link> 
                      ) : null }

                      {column.type === "number" ? value : null}
                      {column.type === "pointer" ? (
                        <Link to={`/CompetitionPreset/${value?.id}/show`}>
                          {
                            data?.[0].attributes?.preset?.attributes?.game
                              ?.attributes.title
                          }{" "}
                          -{" "}
                          {
                            data?.[0].attributes?.preset?.attributes
                              ?.blockchainID
                          }
                        </Link>
                      ) : null}
                      {column.type === "date"
                        ? value?.toLocaleString("en-US")
                        : null}
                      {column.type === "boolean" ? (
                        value ? (
                          <CheckIcon />
                        ) : (
                          <CloseIcon />
                        )
                      ) : null}
                      {column.type === "string" ? (value ? `${value}`: `-`) : null}
                      {column.type === "address" ? (
                        <Hash
                          hash={value}
                          clickable={true}
                          mode="address"
                          shorten={5}
                        />
                      ) : null}
                      {column.type === "tx" ? (
                        <Hash
                          hash={value}
                          clickable={true}
                          mode="tx"
                          shorten={5}
                        />
                      ) : null}
                      {column.type === "sideScores" ? sideScore(value) : null}
                      {column.type === "editable" ? (
                        column.id === "trackingStart" ? (
                          <>
                            <DatePickerTime
                              startDate={startDate}
                              setStartDate={setStartDate}
                              propTime={value}
                            />
                          </>
                        ) : column.id === "trackingFinish" ? (
                          <>
                            <DatePickerTime
                              startDate={finishDate}
                              setStartDate={setFinishDate}
                              propTime={value}
                            />
                          </>
                        ) : null
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export { RelationRow };
