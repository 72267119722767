import React from "react";
import { Admin, Resource, Title } from "react-admin";
import { dataProvider } from "./Parse/parseConfig";
import authProvider from "./auth/authProvider";

// list pages
import {
  UserList,
  ConfigList,
  CompetitionPresetList,
  CompetitionList,
  CompetitorStatusShow,
  TokenTransactionList,
  LedgerTransactionList,
  LedgerTransactionShow,
  GameList,
  BlockchainList,
  ApiCallLogList,
  KYCList,
  AirdropVestingList,
  ReferralList,
  CompetitorStatusList,
} from "./pages";

// show pages
import {
  // UserShow,
  CompetitionShow,
  BlockchainShow,
  ApiCallLogShow,
} from "./pages";

// edit pages
import { UserEdit, CompetitorStatusEdit, CompetitionPresetEdit } from "./pages";
// create pages
import { CompetitionPresetCreate } from "./pages";
// icons
import {
  PersonRoundedIcon,
  SettingsIcon,
  AccountTreeIcon,
  SportsEsportsIcon,
  AccountBalanceWalletIcon,
  GamesIcon,
  SwapVertIcon,
  SwapCallsIcon,
  FolderOpenIcon,
  EmojiEventsIcon,
  VerticalAlignCenterIcon,
  LockIcon,
} from "./components/icons";
import { CustomLoginPage, LightTheme } from "./theme";
import TreeMenu from "@bb-tech/ra-treemenu";
import { Test } from "./pages";
import { Dashboard } from "./pages";
import { Authenticated } from "react-admin";
import { Route } from "react-router-dom";

const CustomRoutes = [
  <Route
    path="/undefined"
    render={() => (
      <Authenticated>
        <Dashboard />
      </Authenticated>
    )}
  />,
];

const App = () => {
  const generateNetworkName = () => {
    if (process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID === "0x61") {
      return "Development [BSC Testnet]";
    }
    else if (process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID === "0x38") {
      return "Production [BSC Mainnet]";
    }
    else if (process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID === "0x89") {
      // return "Production [Polygon Mainnet]"
      return "Production"
    }
    else if (process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID === "0x13881") {
      // return "Development [Polygon Testnet (Mumbai)]"
      return "Development"
    }
    else {
      return "";
    }
  }
  return (
    <Admin
      customRoutes={CustomRoutes}
      // dashboard={Dashboard}
      theme={LightTheme}
      loginPage={CustomLoginPage}
      menu={TreeMenu}
      dataProvider={dataProvider}
      authProvider={authProvider(
        process.env.REACT_APP_PARSE_SERVER_URL,
        process.env.REACT_APP_PARSE_APP_ID,
        process.env.REACT_APP_PARSE_JAVASCRIPT_KEY
      )}
    >
      <Title
        className="admin-title"
        title={generateNetworkName()}
      />
      <Resource
        name="User"
        edit={UserEdit}
        list={UserList}
        icon={PersonRoundedIcon}
        options={{ label: "Users" }}
      // show={UserEdit}
      />
      <Resource
        name="Competition"
        list={CompetitionList}
        icon={SportsEsportsIcon}
        options={{ label: "Competitions" }}
        show={CompetitionShow}
      />
      <Resource
        name="CompetitorStatus"
        list={CompetitorStatusList}
        show={CompetitorStatusShow}
        edit={CompetitorStatusEdit}
        options={{ label: "CompetitorStatus" }}
      />
      {/* <Resource name={"Referral"} list={ReferralList} /> */}

      <Resource
        name="transactions"
        options={{ label: "Transactions", isMenuParent: true }}
        icon={FolderOpenIcon}
      />
      <Resource
        name="Transaction"
        list={LedgerTransactionList}
        // show={LedgerTransactionShow}
        icon={AccountBalanceWalletIcon}
        options={{ label: "Transaction", menuParent: "transactions" }}
      />
      <Resource
        name="Logs"
        options={{ label: "Logs", isMenuParent: true }}
        icon={FolderOpenIcon}
      />
      {/* <Resource
        name="BlockchainLog"
        list={BlockchainList}
        icon={AccountTreeIcon}
        options={{ label: "BlockchainLog", menuParent: "Logs" }}
        show={BlockchainShow}
      /> */}
      <Resource
        name="APICallLog"
        list={ApiCallLogList}
        icon={SwapVertIcon}
        options={{ label: "ApiCallLog", menuParent: "Logs" }}
        show={ApiCallLogShow}
      />

      <Resource
        name="Stats"
        options={{ label: "Stats", isMenuParent: true }}
        icon={FolderOpenIcon}
      />
      <Resource
        name="_User"
        list={KYCList}
        icon={AccountTreeIcon}
        options={{ label: "KYC", menuParent: "Stats" }}
      />

      <Resource
        name="system"
        options={{ label: "System", isMenuParent: true }}
        icon={FolderOpenIcon}
      />
      <Resource
        name="Game"
        list={GameList}
        options={{ label: "Games", menuParent: "system" }}
        icon={GamesIcon}
      />
      <Resource
        name="CompetitionPreset"
        list={CompetitionPresetList}
        icon={AccountTreeIcon}
        options={{ label: "Presets", menuParent: "system" }}
        edit={CompetitionPresetEdit}
        create={CompetitionPresetCreate}
      />
      <Resource
        name="Config"
        list={ConfigList}
        icon={SettingsIcon}
        options={{ label: "Configs", menuParent: "system" }}
      />

      <Resource
        name="Campaigns"
        options={{ label: "Campaigns", isMenuParent: true }}
        icon={FolderOpenIcon}
      />
      <Resource
        name="AirdropVesting"
        list={AirdropVestingList}
        icon={LockIcon}
        options={{ label: "airdrop vesting", menuParent: "Campaigns" }}
      />
      {/* <Resource
        name="test"
        list={Test}
        options={{ label: "test cloud func" }}
      /> */}
    </Admin>
  );
};

export default App;
