import Parse from "parse/lib/browser/Parse";

function userLevelChanger({ target }, notify, record, setLevel) {
  setLevel(target.value);
  const sourceParseClass = Parse.Object.extend("User");
  const sourceObject = new sourceParseClass();
  sourceObject.id = record.id;
  let res = sourceObject.save({ ["level"]: target.value });
  res
    .then((result) => {
      notify("Successfully saved", { type: "success" });
      console.log(result);
    })
    .catch((error) => {
      notify("Error Occurred", { type: "error" });
      console.log(error);
    });
}

function getUserLevelOptions() {
  const sourceParseClass = Parse.Object.extend("Config");
  console.log(sourceParseClass, "getUserLevelOptions");
}

export { userLevelChanger, getUserLevelOptions };
