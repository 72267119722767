import React from "react";
import Parse from "parse";

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  DateField,
  ShowButton,
  FunctionField,
  Pagination,
} from "react-admin";
import { AvatarField, QueryLinking } from "../../components";
import { CustomPagination } from "../../theme/customs/CustomPagination";

const UserList = (props) => {
  console.log("UserList")
  const [selectedDates, setSelectedDates] = React.useState(null);

  const exportUserExcel = async () => {
    const result = await Parse.Cloud.run("export-users", {
      datePeriod: selectedDates,
    })
    const url = result._url;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "CompetitionsReport.xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <List
      bulkActionButtons={false}
      filters={categoryFilters}
      pagination={<CustomPagination />}
      {...props}
      perPage={50}
      sort={{ field: "createdAt", order: "DESC" }}
      exporter={exportUserExcel}
    >
      <Datagrid>
        <AvatarField subClass="avatar" subValue="_url" />
        <FunctionField
          label="Username"
          render={(record) => {
            return (
              <span>
                {record.username} &nbsp;{" "}
                {record.verifiedAt ? (
                  <span className="verify-email-text">
                    {" "}
                    <strong className="checkmark">✔</strong>{" "}
                  </span>
                ) : (
                  ""
                )}
              </span>
            );
          }}
        />
        <FunctionField
          label="Full name"
          render={(record) =>
            record.name ? (
              <span>
                {record.name} {record.family} &nbsp;{" "}
                <strong>{record.KYCAt ? "✔" : ""}</strong>{" "}
              </span>
            ) : (
              ""
            )
          }
        />
        <FunctionField
          label="Email"
          render={(record) =>
            record.email ? (
              <span>
                {record.email} &nbsp;{" "}
                <strong>{record.emailVerified ? "✔" : ""}</strong>{" "}
              </span>
            ) : (
              ""
            )
          }
        />
        <TextField source="country" label="Country" />
        <TextField source="level" label="User Level" />
        <QueryLinking
          subClass="referrer"
          label="Refferrer"
          subClassRefrence="referralCode"
        />
        <DateField source="createdAt" label="Created at" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const categoryFilters = [
  <TextInput source="username" label="Username" alwaysOn />,
  <TextInput source="email" label="Email" />,
  <TextInput source="country" label="Country" />,
];

export { UserList };
