import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

const exportCsv = (datas, nameExport) => {
  const editedData = datas.map((data) => {
    // const { ...data } = data; // omit those headers u dont want
    data.groupStartTime = new Date(data.groupStartTime); // add a field
    return data;
  });
  jsonExport(
    editedData,
    {
      // headers: ["id", "title", "author_name", "body"], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, nameExport); // download as 'posts.csv` file
    }
  );
};

export { exportCsv };
