import Avatar from '@material-ui/core/Avatar/index';
import { useEffect, useState } from 'react';

const AvatarField = (props) => {
  
  const {subClass,subValue,record} = props ;
  const [url,setUrl] = useState('');

  useEffect(() => {
    if(record){
      if(record[subClass]){
        const parseFile = record[subClass];
        if(parseFile[subValue]){
          setUrl(parseFile[subValue])
        }
      }
    }
    //eslint-disable-next-line
  },[])
  return (
    <Avatar src={url} />
  )
}

export {AvatarField}