import { useEffect, useState } from "react";
import Parse from "parse";
import DatePicker from "react-datepicker";

const FilterDate = (props) => {
  const { setChange, setData, resourse } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (typeof props.selectedDates === 'function') {
      props.selectedDates({startDate, endDate});
    }
  }, [startDate, endDate]);

  async function filterDate(startDate, endDate) {
    try {
      const query = new Parse.Query(resourse);
      query.limit(500);
      query.greaterThanOrEqualTo("createdAt", startDate);
      query.lessThanOrEqualTo("createdAt", endDate);

      let result = await query.find();
      console.log("res is", result);

      setData(result.reverse());
    } catch (error) {
      console.log("error is", error);
    }
  }



  async function applyDateFilter() {
    filterDate(startDate, endDate);
    setChange(true);
  }
  return (
    <div className="filter-date-holder">
      <div>
        <span>Start Date:</span>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          timeFormat="HH:mm"
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
          showTimeInput
          timeInputLabel="Time:"
        />
      </div>
      <div>
        <span>End Date:</span>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          timeFormat="HH:mm"
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
          showTimeInput
          timeInputLabel="Time:"
        />
      </div>
      <button className="button-9" onClick={applyDateFilter}>
        Apply Filter
      </button>
    </div>
  );
};

export { FilterDate };
