import * as bscMainnet from "./0x38";
import * as bscTestnet from "./0x61";
import * as polygonMainnet from "./0x89";
import * as polygonMumbai from "./0x13881";

const allNetworks = {
  "0x38": bscMainnet,
  "0x61": bscTestnet,
  "0x89": polygonMainnet,
  "0x13881": polygonMumbai,
};
const network = allNetworks[process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID];
export const {
  addNewPreset,
  updateTokenRate,
  updateTokenPrice,
  updatePreset,
  // evolveStorageContract,
  // evolveFactoryContract,

  lockTokens,

  getAddressScannerURL,
  getTxScannerURL,
} = network;
