import React from "react";

import {
  List,
  Datagrid,
  DateField,
  ShowButton,
  FunctionField,
  TextField,
  SelectInput,
} from "react-admin";
import { PointerField, CustomTable } from "../../components";
import { Link } from "react-router-dom";
import { FilterDate } from "../../components/FilterDate";
import { CustomPagination } from "../../theme/customs/CustomPagination";
import Parse from "parse";

const columns = [
  {
    id: "competition",
    label: "Competition",
    type: "pointers",
    resourse: "Competition",
    displayText: "objectId",
  },
  {
    id: "user",
    label: "User",
    type: "pointers",
  },
  {
    id: "createdAt",
    label: "Created at",
    type: "date",
  },
  {
    id: "status",
    label: "Status",
    type: "string",
  },
  {
    id: "apiStatsFetchedAt",
    label: "api Stats Fetched at",
    type: "date",
  },
  {
    id: "joinedCompetitionAt",
    label: "joinedCompetitionAt",
    type: "date",
  },
  {
    id: "matchStartedAt",
    label: "matchStartedAt",
    type: "date",
  },
  {
    id: "matchEndedAt",
    label: "matchEndedAt",
    type: "date",
  },
  {
    id: "conclusion",
    label: "Conclusion",
    type: "string",
  },
  {
    id: "apiStats",
    label: "apiStats",
    type: "string",
  },
  {
    id: "objectId",
    label: "API Call Logs",
    type: "special1",
  },
  {
    id: "objectId",
    label: "Show",
    type: "linking",
    linking: "CompetitorStatus",
  },
];

const CompetitorStatusList = (props) => {
  const [change, setChange] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [selectedDates, setSelectedDates] = React.useState(null);

  const exportCompetitorExcel =async () => {
    const result = await Parse.Cloud.run("export-competitors", {
      datePeriod: selectedDates,
    })
    const url = result._url;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "CompetitorsReport.xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };


  return (
    <>
      <FilterDate
        setChange={setChange}
        setData={setData}
        resourse="CompetitorStatus"
        selectedDates={setSelectedDates}
      />
      {change ? (
        <CustomTable
          data={data}
          columns={columns}
          title={""}
          infinite={true}
          perPage={50}
        />
      ) : (
        <List
          pagination={<CustomPagination />}
          bulkActionButtons={false}
          filters={categoryFilters}
          {...props}
          perPage={50}
          sort={{ field: "createdAt", order: "DESC" }}
          exporter={exportCompetitorExcel}
        >
          <Datagrid>
            <PointerField
              isLinked={true}
              reference="Competition"
              subClass="competition"
              subValue="objectId"
              label="Competition"
            />
            <PointerField
              isLinked={true}
              reference="User"
              subClass="user"
              subValue="username"
              label="User"
            />
            <DateField showTime={true} source="createdAt" label="Created at" />
            <TextField source="status" label="Status" />
            <DateField
              showTime={true}
              source="apiStatsFetchedAt"
              label="apiStatsFetchedAt"
            />
            <DateField
              showTime={true}
              source="joinedCompetitionAt"
              label="joinedCompetitionAt"
            />
            <DateField
              showTime={true}
              source="matchStartedAt"
              label="matchStartedAt"
            />
            <DateField
              showTime={true}
              source="matchEndedAt"
              label="matchEndedAt"
            />
            <PointerField
              isLinked={true}
              reference="APICallLog"
              subClass="apiCallLog"
              subValue="objectId"
              label="Last API call log"
            />
            <FunctionField
              label={"Conclusion"}
              render={(record) => {
                if (record.conclusion) {
                  if (record.conclusion.status === "matchType")
                    return "Wrong match type";
                  else if (record.conclusion.status === "gameMode")
                    return "Wrong game mode";
                  else if (record.conclusion.status === "isCustomMatch")
                    return "Wrong custom match";
                  else return record.conclusion.status;
                } else return "-";
              }}
            />
            <FunctionField
              label={"API stats"}
              render={(record) =>
                record.apiStats ? (
                  <>
                    <span>Game mode : {record.apiStats?.gameMode}</span>
                    <br />
                    <span>Kills : {record.apiStats?.kills}</span>
                    <br />
                    <span>Map name : {record.apiStats?.mapName}</span>
                    <br />
                  </>
                ) : (
                  "-"
                )
              }
            />
            <FunctionField
              render={(record) => (
                <Link
                  to={`/APICallLog?displayedFilters=%7B%7D&filter=%7B"competitor"%3A"${record.id}"%7D&order=DESC&page=1&perPage=50&sort=createdAt`}
                >
                  API Call Logs
                </Link>
              )}
            />
            <ShowButton />
          </Datagrid>
        </List>
      )}
    </>
  );
};

const categoryFilters = [
  <SelectInput
    alwaysOn
    source="status"
    label="Status"
    choices={[
      { id: "COMPETITOR_IS_READY", name: "COMPETITOR_IS_READY" },
      { id: "WAITING", name: "WAITING" },
      { id: "PENDING_READY", name: "PENDING_READY" },
      { id: "PENDING_READY_CANCELED", name: "PENDING_READY_CANCELED" },
      { id: "PENDING_READY_TIMEDOUT", name: "PENDING_READY_TIMEDOUT" },
      { id: "READY_TO_PLAY", name: "READY_TO_PLAY" },
      { id: "SHOULD_PLAY", name: "SHOULD_PLAY" },
      { id: "FINISH_BY_API", name: "FINISH_BY_API" },
      { id: "NO_API_BEFORE_TIMEOUT", name: "NO_API_BEFORE_TIMEOUT" },
    ]}
  />,
];
export { CompetitorStatusList };
