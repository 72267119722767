import {
  stringHandler,
  dateHandler,
  booleanHandler,
  objectHandler,
  numberHandler,
} from "./typeHandlers";

function configEditableFields(field) {
  switch (field) {
    case "User":
      return userEditableFields;
    case "CompetitionPreset":
      return competitionPresetEditableFields;
    case "Compitition":
      return compititionEditableFields;
    default:
      console.log("we are in no where");
  }
}

const userEditableFields = [
  {
    name: "country",
    type: "text",
    typeHandler: stringHandler,
  },
  {
    name: "verifiedAt",
    type: "date",
    typeHandler: dateHandler,
  },
  {
    name: "icoSwapEnabled",
    type: "boolean",
    typeHandler: booleanHandler,
  },
  {
    name: "icoFiatEnabled",
    type: "boolean",
    typeHandler: booleanHandler,
  },
  {
    name: "icoWireEnabled",
    type: "boolean",
    typeHandler: booleanHandler,
  },
  {
    name: "icoEnabled",
    type: "boolean",
    typeHandler: booleanHandler,
  },
  {
    name: "gamingEnabled",
    type: "boolean",
    typeHandler: booleanHandler,
  },
];

const competitionPresetEditableFields = [
  {
    name: "players",
    type: "number",
    typeHandler: numberHandler,
  },
  {
    name: "feeInUSD",
    type: "number",
    typeHandler: numberHandler,
  },
  {
    name: "promotion",
    type: "text",
    typeHandler: stringHandler,
  },
  {
    name: "active",
    type: "boolean",
    typeHandler: booleanHandler,
  },
  {
    name: "description",
    type: "text",
    typeHandler: stringHandler,
  },
  {
    name: "KYCRequired",
    type: "boolean",
    typeHandler: booleanHandler,
  },
  {
    name: "comingSoon",
    type: "boolean",
    typeHandler: booleanHandler,
  },
  {
    name: "settings",
    type: "object",
    typeHandler: objectHandler,
  },
  {
    name: "levelRequired",
    type: "number",
    typeHandler: numberHandler,
  },
  {
    name: "currency",
    type: "text",
    typeHandler: stringHandler,
  },
  {
    name: "botMode",
    type: "text",
    typeHandler: stringHandler,
  },
  {
    name: "rakeInUsd",
    type: "number",
    typeHandler: numberHandler,
  },
];

const compititionEditableFields = [
  { name: "trackingFinish", type: "date", typeHandler: dateHandler },
  { name: "trackingStart", type: "date", typeHandler: dateHandler },
];

export { configEditableFields };
