import {
  Show,
  SimpleShowLayout,
} from "react-admin";
import { CustomTopToolbar } from '../../theme';
import { ApiCallLogDetail } from "./ApiCallLogDetail";
import { TitleNavbar } from "../../components";

const ApiCallLogShow = (props) => {
  return (
    <Show {...props} actions={<CustomTopToolbar />} title={<TitleNavbar mode="Show" field="username" />} >
      <SimpleShowLayout>
        <ApiCallLogDetail />
      </SimpleShowLayout>
    </Show>
  )
}

export { ApiCallLogShow }