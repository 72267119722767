import { SimpleForm, Edit, useNotify } from "react-admin";
import { CompetitorStatusDetail } from "./CompetitorStatusDetail";
import { saveHandler, configEditableFields } from "../../api";
import { TitleNavbar } from "../../components";

const CompetitorStatusEdit = (props) => {
  const notify = useNotify();
  async function callHandler(data) {
    try {
      await saveHandler(
        configEditableFields("CompetitorStatus"),
        data,
        props.resource,
        props.id
      );
      notify("changes saved");
    } catch (error) {
      notify(`Error : ${error.message}`, { type: "error" });
      console.log(error);
    }
  }
  return (
    <Edit {...props} title={<TitleNavbar mode="Edit" field="username" />}>
      <SimpleForm save={(data) => callHandler(data)}>
        <CompetitorStatusDetail mode="edit" />
      </SimpleForm>
    </Edit>
  );
};

export { CompetitorStatusEdit };
