import {
  FunctionField, TextField, Link,
} from "react-admin";
// import { QueryLinking } from '../../components';
import ReactJson from 'react-json-view'

// use the component in your app!
const ApiCallLogDetail = (props) => {
  // const { record } = props;
  return (
    <>
     <section className="wrapper wrapper-pre ">
        <div>
        <h1>URL:</h1>
       <TextField source="url" label="URL" />
        </div>
        <div>
        <h1>Competition:</h1>
        <FunctionField
              label={"Competition"}
              render={(record) => (
                <Link
                  to={`/${"Competition"}/${record["competition"]?.id}/show`}
                >
                  {record["competition"]?.id}
                </Link>
              )}
            />
        </div>
        <div>
        <h1>Competitor:</h1>
        <FunctionField
              label={"Competitor"}
              render={(record) => (
                <Link
                  to={`/${"CompetitorStatus"}/${record["competitor"]?.id}/show`}
                >
                  {record["competitor"]?.id}
                </Link>
              )}
            />
        </div>
      </section>
      <section className="wrapper wrapper-pre ">
        <h1>Response:</h1>
        <FunctionField render={
          (record) => record.resp ? (
           <pre className="bulk">
            <ReactJson src={record.resp} displayDataTypes={false} quotesOnKeys={false} />
            </pre>
          ) : <p>No Response</p>}
        />
      </section>
    </>
  )
}

export { ApiCallLogDetail };
