import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";
import Parse from "parse";

const authProvider = ({ URL, APP_ID, JAVASCRIPT_KEY }) => {
  if (Parse.applicationId === null || Parse.javaScriptKey === null) {
    Parse.initialize(APP_ID, JAVASCRIPT_KEY);
    Parse.serverURL = URL;
  }

  return async (type, params) => {
    if (type === AUTH_LOGIN) {
      const { username, password } = params;
      try {
        const user = await Parse.User.logIn(username, password);
        return user;
      } catch (error) {
        throw error.message;
      }
    }
    if (type === AUTH_LOGOUT) {
      try {
        await Parse.User.logOut();
        return Promise.resolve();
      } catch (error) {
        throw Error(error.toString());
      }
    }
    if (type === AUTH_ERROR) {
      // ...
      // Parse.User.logOut().then
      return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
      return Parse.User.current() ? Promise.resolve() : Promise.reject();
    }

    return Promise.reject("Unknown method");
  };
};

export default authProvider;
