import React from "react";
// import Button from "@material-ui/core/Button";
import {
  List,
  Datagrid,
  DateField,
  FunctionField,
  ShowButton,
  TextField,
  // useNotify,
} from "react-admin";

// import { MetamaskInteraction } from "../../components/metamask/MetamaskInteraction";
import { PointerField } from "../../components";
import { UpdateSwitchBtn } from "../../components";
// import { addNewPreset, getTxScannerURL } from "../../contracts";
// import Parse from "parse";
// import { toWei } from "../../utils/metamaskHelper";
import { CustomPagination } from "../../theme/customs/CustomPagination";

const CompetitionPresetList = (props) => {
  // const notify = useNotify();
  // const [buttonDisable, setButtonDisable] = React.useState(false);

  // const addBlockchain = async (record) => {
  //   setButtonDisable(true);
  //   try {
  //     const { transactionHash } = await addNewPreset(
  //       toWei(record.feeInUSD),
  //       record.players,
  //       record.createdAt.getTime(),
  //       toWei(record.rakeInUsd)
  //     );

  //     const Preset = Parse.Object.extend("CompetitionPreset");
  //     const preset = Preset.createWithoutData(record.id);
  //     await preset.save({ storageTx: transactionHash });
  //     notify("Changes saved");
  //   } catch (error) {
  //     console.log("err is", error);
  //     notify(`Error : ${error.message}`, { type: "error" });
  //   }
  //   setButtonDisable(false);
  // };

  return (
    <>
      <List
        pagination={<CustomPagination />}
        perPage={50}
        bulkActionButtons={false}
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        exporter={false}
      >
        <Datagrid>
          <DateField source="createdAt" label="Created at" />
          <PointerField
            reference="Game"
            subClass="game"
            subValue="title"
            label="Game"
            isLinked={false}
          />
          {/* <FunctionField
            label="Currency"
            render={(record) => `${record.currency}$`}
          /> */}
          <FunctionField
            label="Bot Mode"
            render={(record) => `${record.botMode}`}
          />
          <FunctionField
            label="Rake In Usd"
            render={(record) => `${record.rakeInUsd}`}
          />

          <FunctionField
            label="Fee"
            render={(record) => `${record.feeInUSD}$`}
          />
          <FunctionField
            label="Players"
            render={(record) => `${record.players} v ${record.players}`}
          />
          {/* <FunctionField
            label="Blockchain ID"
            render={(record) =>
              record.feeInUSD === 0 ? (
                "N/A"
              ) : record.blockchainID ? (
                record.storageTx ? (
                  <a
                    href={getTxScannerURL(record.storageTx)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {record.blockchainID}
                  </a>
                ) : (
                  record.blockchainID
                )
              ) : record.storageTx ? (
                "Syncing..."
              ) : (
                <MetamaskInteraction>
                  <Button
                    onClick={() => addBlockchain(record)}
                    className="add-blockchain-id-button"
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={buttonDisable}
                  >
                    {buttonDisable ? "Loading..." : "add to blockchain"}
                  </Button>
                </MetamaskInteraction>
              )
            }
          /> */}
          <FunctionField
            label="Setting"
            render={(record) => record.settings?.title}
          />
          <TextField source="promotion" />
          <FunctionField
            label="Coming soon"
            render={(record) => (record.comingSoon ? <strong>✓</strong> : "")}
          />
          <FunctionField
            label="KYCRequired"
            render={(record) => (record.KYCRequired ? <strong>✓</strong> : "")}
          />
          <TextField source="levelRequired" label="Level required" />
          <UpdateSwitchBtn field="active" label="Active" />
          <ShowButton />
        </Datagrid>
      </List>
      {/* <DialogPreset
        open={open}
        setOpen={setOpen}
        record={data}
        setButtonDisable={setButtonDisable}
      /> */}
    </>
  );
};

export { CompetitionPresetList };
