import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useGetChartData } from "../../api/customHooks/useGetChartData";
import { KYCChart } from "./KYCChart";
import "react-datepicker/dist/react-datepicker.css";
import { exportCsv } from "../../api";
import { ExportBtn } from "../../components";

export const KYCList = () => {
  const [startDate, setStartDate] = useState(subtractMonths(1));
  const [endDate, setEndDate] = useState(new Date());
  const [duration, setDuration] = useState(24 * 60 * 60 * 1000);

  function subtractMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() - numOfMonths);
    return date;
  }

  const fetchedKycData = useGetChartData({
    duration: duration,
    start: startDate ? startDate : null,
    end: endDate,
  });

  return (
    <div>
      <div className="kyc-nav-container margin-top-1">
        <h1>KYC statistics</h1>
        <ExportBtn
          onClick={() =>
            exportCsv(
              fetchedKycData.data,
              `${startDate.getFullYear()}/${
                startDate.getMonth() + 1
              } to ${endDate.getFullYear()}/${endDate.getMonth() + 1}`
            )
          }
        />
        <div className="kyc-date-picker-container">
          <div>
            <p className="font-bold">From date</p>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div>
            <p className="font-bold">To date</p>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
        </div>
      </div>
      <KYCChart
        fetchedKycData={fetchedKycData.data}
        startDate={startDate}
        endDate={endDate}
        defaultDuration={duration}
        durationToParent={(duration) => setDuration(duration)}
      />
    </div>
  );
};
