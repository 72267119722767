import { useEffect } from "react";
// import { useState } from "react";
import DatePicker from "react-datepicker";

const DatePickerTime = ({ propTime, setStartDate, startDate }) => {
  useEffect(() => {
    if (propTime) {
      setStartDate(new Date(`${propTime}`));
    }
    if (propTime?.iso) {
      setStartDate(new Date(`${startDate}`));
    }
  }, [propTime]);

  function setHandler(date) {
    setStartDate(date);
  }

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => setHandler(date)}
      timeFormat="HH:mm"
      timeCaption="time"
      dateFormat="MMMM d, yyyy h:mm aa"
      showTimeInput
      timeInputLabel="Time:"
    />
  );
};

export { DatePickerTime };
