import { useEffect, useState } from "react";
import Parse from "parse";
import { useNotify } from "react-admin";

const useGetChartData = (params = {}) => {
  const notify = useNotify();
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const { start, end, duration } = params;
  useEffect(() => {
    Parse.Cloud.run("get-kyc-stats", params)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        setData("error occured", err);
        setError(true);
        notify(`Error: ${err.message}`, { type: "error" });
      });
    //eslint-disable-next-line
  }, [start, end, duration]);
  return { data, error };
};

export { useGetChartData };
