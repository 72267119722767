// import Parse from "parse";

// // const redirect = useRedirect()

// async function saveHandler(configs, formDatas, resource, id) {
//   try {
//     const query = new Parse.Query(resource);
//     let res = await query.get(id);
//     configs.forEach((el) => {
//       let configType = el.typeHandler;
//       res.set(el.name, configType(formDatas[el.name]));
//     });
//     let res2 = await res.save();
//     console.log("successful response", res2);

//     return res2;
//   } catch (error) {
//     console.log("Error:", error);
//     return error;
//   }
// }

// export { saveHandler };

import Parse from "parse";

// const redirect = useRedirect()

function saveHandler(configs, formDatas, resource, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const query = new Parse.Query(resource);
      let res = await query.get(id);
      configs.forEach((el) => {
        let configType = el.typeHandler;
        res.set(el.name, configType(formDatas[el.name]));
      });
      let res2 = await res.save();
      resolve(res2);
    } catch (error) {
      reject(error);
    }
  });
}

export { saveHandler };
