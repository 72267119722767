const ExportBtn = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-6 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
      tabIndex="0"
      type="button"
      aria-label="Export"
    >
      <span className="MuiButton-label">
        <svg
          className="MuiSvgIcon-root RaButton-smallIcon-9"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
        </svg>
        <span className="RaButton-label-7">Export</span>
      </span>
    </button>
  );
};

export { ExportBtn };
