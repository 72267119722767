import {
  List,
  Datagrid,
  DateField,
  ShowButton,
  TextField,
  SelectInput,
  TextInput,
  FunctionField,
  Link,
} from "react-admin";
import { CustomPagination } from "../../theme/customs/CustomPagination";
import { useState } from "react";
import { CustomTable } from "../../components";
import { FilterDate } from "../../components/FilterDate";

const columns = [
  {
    id: "createdAt",
    label: "Created at",
    type: "date",
  },
  {
    id: "updatedAt",
    label: "Updated at",
    type: "date",
  },

  {
    id: "resp_code",
    label: "resp_code",
    type: "string",
  },
  {
    id: "resp_time",
    label: "resp_time",
    type: "string",
  },
  {
    id: "competition",
    label: "Competition",
    type: "simplePointer",
  },
  {
    id: "objectId",
    label: "Show",
    type: "linking",
    linking: "APICallLog",
  },
];

const ApiCallLogList = (props) => {
  const [change, setChange] = useState(false);
  const [data, setData] = useState(null);

  return (
    <>
      <FilterDate
        setChange={setChange}
        setData={setData}
        resourse="APICallLog"
      />
      {change ? (
        <CustomTable
          data={data}
          columns={columns}
          title={""}
          infinite={true}
          perPage={50}
        />
      ) : (
        <List
          pagination={<CustomPagination />}
          bulkActionButtons={false}
          filters={categoryFilters}
          perPage={50}
          {...props}
          sort={{ field: "createdAt", order: "DESC" }}
          exporter={false}
        >
          <Datagrid>
            <DateField showTime={true} source="createdAt" label="Created at" />
            <DateField showTime={true} source="updatedAt" label="Updated at" />
            <TextField source="resp_code" label="resp_code" />
            <TextField source="resp_time" label="resp_time" />
            <FunctionField
              label={"Competition"}
              render={(record) => (
                <Link
                  to={`/${"Competition"}/${record["competition"]?.id}/show`}
                >
                  {record["competition"]?.id}
                </Link>
              )}
            />
              <FunctionField
              label={"Competitor"}
              render={(record) => (
                <Link
                  to={`/${"CompetitorStatus"}/${record["competitor"]?.id}/show`}
                >
                  {record["competitor"]?.id}
                </Link>
              )}
            />
            <ShowButton />
          </Datagrid>
        </List>
      )}
    </>
  );
};

const categoryFilters = [
  // <SelectInput
  //   alwaysOn
  //   source="status"
  //   label="Status"
  //   choices={[{ id: "COMPETITOR_IS_READY", name: "COMPETITOR_IS_READY" }]}
  // />,
  <TextInput source="competition" alwaysOn />,
  <TextInput source="competitor" alwaysOn />,
];
export { ApiCallLogList };
