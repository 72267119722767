import { Link } from "react-admin";
import { useEffect, useState } from "react";
import Parse from "parse";
const QueryLinking = (props) => {
  const { record, subClass, resource, subClassRefrence } = props;
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (record) {
      if (record[subClass]) {
        const query = new Parse.Query(resource);
        query.equalTo(subClassRefrence, record[subClass]);
        query
          .find()
          .then((res) => {
            setUrl(`/${resource}/${res?.[0]?.id}/show`);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
    //eslint-disable-next-line
  }, [record]);

  return (
    <>
      {url ? (
        <Link to={url}>
          <span className="MuiTypography-root MuiTypography-body2 referrer">
            {record[subClass]}
          </span>
        </Link>
      ) : null}
    </>
  );
};

export { QueryLinking };
