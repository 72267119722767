import Parse from "parse";

async function createHandler(resource, configs, formDatas) {
  return new Promise(async (resolve, reject) => {
    try {
      const Class = Parse.Object.extend(resource);
      const newSubClass = new Class();
      for (let i = 0; i < configs.length; i++) {
        let el = configs[i];
        const configType = el.typeHandler;
        const response = await configType(formDatas[el.name], "Game");
        console.log(el.name, response);
        newSubClass.set(el.name, response);
      }
      const result = await newSubClass.save();
      resolve(result);
    } catch (error) {
      console.log("we fall into error", error);
      reject(error);
    }
  });
  // const Class = Parse.Object.extend(resource);
  // const newSubClass = new Class();
  // try {
  //   for (let i = 0; i < configs.length; i++) {
  //     let el = configs[i];
  //     const configType = el.typeHandler;
  //     const response = await configType(formDatas[el.name], "Game");
  //     console.log(el.name, response);
  //     newSubClass.set(el.name, response);
  //   }
  //   const result = await newSubClass.save();
  //   return result;
  // } catch (error) {
  //   console.log("we fall into error", error);
  //   return error;
  // }
}

export { createHandler };

// import Parse from "parse";

// async function createHandler(resource, configs, formDatas) {
//   return new Promise((resolve, reject) => {
//     const Class = Parse.Object.extend(resource);
//     const newSubClass = new Class();
//     for (let i = 0; i < configs.length; i++) {
//       let el = configs[i];
//       const configType = el.typeHandler;
//       const response = configType(formDatas[el.name], "Game");
//       newSubClass.set(el.name, response);
//     }
//     newSubClass
//       .save()
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// }

// export { createHandler };
