import {
  Show,
  SimpleShowLayout,
} from "react-admin";
import { CustomTopToolbar } from '../../theme';
import { CompetitorStatusDetail } from "./CompetitorStatusDetail";
import { TitleNavbar } from "../../components";

const CompetitorStatusShow = (props) => {
  return (
    <Show {...props} actions={<CustomTopToolbar />} title={<TitleNavbar mode="Show" field="username" />} >
      <SimpleShowLayout>
        <CompetitorStatusDetail />
      </SimpleShowLayout>
    </Show>
  )
}

export { CompetitorStatusShow }