import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Checkbox } from "@material-ui/core";
import Parse from "parse";
import { useNotify } from "react-admin";
import Button from "@material-ui/core/Button";

const EditUserLevelTable = (props) => {
  const { columns, title, record, passToParent, handleSaveLevelsConfig } =
    props;
  const notify = useNotify();
  const Config = Parse.Object.extend("Config");

  const [instanceRecord, setInstanceRecord] = useState(record);

  const useStyles = makeStyles({
    root: {
      width: "100%",
      marginTop: title ? "5px" : "70px",
      marginBottom: "25px",
    },
    container: {},
    MuiTableCell: {
      padding: 20,
    },
    customPadding: {
      padding: `6px 24px 6px 16px`,
    },
    center: {
      textAlign: "center",
    },
    mt: {
      marginTop: "20px",
    },
    removeButton: {
      backgroundColor: "#f50057",
      color: "white",
      "&:hover": {
        backgroundColor: "#ab003c",
        color: "white",
      },
    },
  });

  const classes = useStyles();

  useEffect(() => {
    passToParent(instanceRecord);
  }, [instanceRecord]);

  const addNewRow = async () => {
    const configQuery = new Parse.Query(Config);
    configQuery.equalTo("variable", "level-requirements");
    const config = await configQuery.first();
    const levelRequirements = config.get("listValue");
    const newLevelRequirements = [...levelRequirements, {}];
    config.set("listValue", newLevelRequirements);
    let result = config.save();
    result
      .then((res) => {
        setInstanceRecord(res.attributes);
        notify("New Level Added", { type: "success" });
      })
      .catch((err) => {
        console.log("err", err);
        notify("Error occurred", { type: "error" });
      });
  };

  const removeLevel = async (index) => {
    const configQuery = new Parse.Query(Config);
    configQuery.equalTo("variable", "level-requirements");
    const config = await configQuery.first();
    const levelRequirements = config.get("listValue");
    const newLevelRequirements = [
      ...levelRequirements.slice(0, index),
      ...levelRequirements.slice(index + 1),
    ];
    config.set("listValue", newLevelRequirements);
    let result = config.save();
    result
      .then((res) => {
        setInstanceRecord(res.attributes);
        notify("Level Removed", { type: "success" });
      })
      .catch((err) => {
        console.log("err", err);
        notify("Error occurred", { type: "error" });
      });
  };

  let config = new Config();
  config.id = record.id;

  function onChangeInput(e, column, rowIndex) {
    instanceRecord.listValue[rowIndex][column.id] = e.target.value;
    config.set("listValue", instanceRecord.listValue);
    handleSaveLevelsConfig(config);
  }

  function onChangeCheckbox(event, column, rowIndex) {
    if (event.target.checked) {
      if (!instanceRecord.listValue[column.id]) {
        instanceRecord.listValue[rowIndex][column.id] = true;
        config.set("listValue", instanceRecord.listValue);
      }
    } else {
      if (!instanceRecord.listValue[column.id]) {
        instanceRecord.listValue[rowIndex][column.id] = false;
        config.set("listValue", instanceRecord.listValue);
      }
    }
    handleSaveLevelsConfig(config);
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.customPadding}> Level </TableCell>
              {columns?.map((column, columnIndex) => (
                <TableCell className={classes.customPadding} key={columnIndex}>
                  {" "}
                  {column.label}{" "}
                </TableCell>
              ))}
              <TableCell className={classes.customPadding}> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {instanceRecord.listValue?.map((row, rowIndex) => {
              if (rowIndex !== 0) {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.rowIndex}
                  >
                    <TableCell key={rowIndex} className={classes.customPadding}>
                      Level {rowIndex}
                    </TableCell>
                    {columns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          className={classes.customPadding}
                        >
                          {column.type === "number" ? (
                            <input
                              type="number"
                              defaultValue={
                                instanceRecord.listValue[rowIndex][column.id]
                              }
                              onChange={(e) =>
                                onChangeInput(e, column, rowIndex)
                              }
                            />
                          ) : null}
                          {column.type === "checkbox" ? (
                            <Checkbox
                              defaultChecked={
                                instanceRecord.listValue[rowIndex][column.id]
                              }
                              onChange={(e) =>
                                onChangeCheckbox(e, column, rowIndex)
                              }
                            />
                          ) : null}
                        </TableCell>
                      );
                    })}
                    <TableCell className={classes.customPadding}>
                      <TableCell
                        key={rowIndex}
                        className={classes.customPadding}
                      >
                        <Button
                          variant="containedSecondary"
                          className={classes.removeButton}
                          onClick={() => removeLevel(rowIndex)}
                        >
                          Remove Level
                        </Button>
                      </TableCell>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="containedSecondary"
        className={classes.mt}
        onClick={addNewRow}
      >
        Add New Level
      </Button>
    </Paper>
  );
};

export { EditUserLevelTable };
