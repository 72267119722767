import { useState, useEffect } from "react";
import Parse from "parse";
import { useNotify } from "react-admin";

// const data = useFilterList('CompetitorStatus','competition','pointer',{pointerClass:'Competition',"pointerId":props.id})

const useFilterPointer = (
  classField,
  filterField,
  typeFilterField,
  pointerDetail,
  sortBy
) => {
  const notify = useNotify();
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (typeFilterField === "pointer") {
      const { pointerClass, pointerId } = pointerDetail;
      const query = new Parse.Query(pointerClass);
      query.equalTo("objectId", pointerId);

      // const fetchData = async () => {
      //   try {
      //     const res = await query.find();
      //     const query2 = new Parse.Query(classField);
      //     query2.include("user");
      //     query2.descending(sortBy);
      //     query2.equalTo(filterField, res[0]);
      //     const res2 = query2.find();
      //     setData(res2);
      //   } catch (error) {
      //     console.log("Error is:", error);
      //     notify("Error Occured", { type: "error" });
      //   }
      // };
      // fetchData();
      query
        .find()
        .then((res) => {
          const query2 = new Parse.Query(classField);
          query2.include("user");
          query2.descending(sortBy);
          query2.equalTo(filterField, res[0]);
          query2
            .find()
            .then((res) => {
              setData(res);
            })
            .catch((err) => {
              setData("error occured", err);
              setError(true);
              notify(`Error: ${err.message}`, { type: "error" });
            });
        })
        .catch((err) => {
          setData("error occured", err);
          setError(true);
          notify(`Error: ${err.message}`, { type: "error" });
        });
    }
    //eslint-disable-next-line
  }, [classField]);

  return { data, error };
};

export { useFilterPointer };
