import Parse from "parse";
// import { async } from 'parse/lib/browser/Storage'

function stringHandler(value) {
  return String(value);
}

function dateHandler(value) {
  let date = new Date(value);
  return { __type: "Date", iso: date.toISOString() };
}

function pointerHandler(objecId, resource) {
  return new Promise((resolve, reject) => {
    const query = new Parse.Query(resource);
    query
      .get(objecId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function numberHandler(value) {
  return Number(value);
}

function booleanHandler(value) {
  return value;
}

function objectHandler(value) {
  if (value) {
    return value;
  } else {
    return {};
  }
}

function arrayHandler(value) {
  return value;
}

export {
  stringHandler,
  dateHandler,
  pointerHandler,
  numberHandler,
  booleanHandler,
  objectHandler,
  arrayHandler,
};
