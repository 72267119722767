import React, { useEffect, useState } from "react";
import { Link, useNotify } from "react-admin";
import Parse from "parse";

const PointerField = (props) => {
  const {
    subClass,
    subValue,
    record,
    reference,
    twoTimes,
    subClass2,
    subValue2,
    isLinked,
    titleAdditionalSubValue,
  } = props;
  const notify = useNotify();
  const [linkName, setLinkName] = useState(record[subClass]?.get(subValue));
  const [titleTwoTimes, setTitleTwoTimes] = useState("");
  useEffect(() => {
    if (window.location.href.split("/").includes("show")) {
      const query = new Parse.Query(reference);
      query.equalTo("objectId", record?.[subClass]?.id);
      query
        .find()
        .then((res) => {
          if (titleAdditionalSubValue) {
            setLinkName(
              res[0].get(subValue) + " - " + res[0].get(titleAdditionalSubValue)
            );
          } else {
            setLinkName(res[0]?.get(subValue));
          }
          record[subClass] = res[0];
        })
        .catch((err) => {
          console.log("err", err);
          notify(`Error: ${err.message}`, { type: "error" });
        });
    }
    if (twoTimes) {
      const queryTwoTimes = new Parse.Query(reference);
      queryTwoTimes.equalTo("objectId", record?.[subClass]?.id);
      queryTwoTimes.include(subClass2);
      queryTwoTimes
        .find()
        .then((res) => {
          if (titleAdditionalSubValue) {
            setTitleTwoTimes(
              res?.[0]?.get(subClass2)?.get(subValue2) +
                " - " +
                res?.[0]?.get(titleAdditionalSubValue)
            );
          } else {
            setTitleTwoTimes(res?.[0]?.get(subClass2)?.get(subValue2));
          }
        })
        .catch((err) => {
          console.log("err", err);
          notify(`Error: ${err.message}`, { type: "error" });
        });
    }
    //eslint-disable-next-line
  }, []);
  return (
    <>
      {isLinked ? (
        <Link
          to={`/${reference}/${record[subClass]?.id}/show`}
          className="pointer-link"
        >
          <span className="MuiTypography-root MuiTypography-body2">
            {twoTimes
              ? titleTwoTimes
              : linkName
              ? linkName
              : record[subClass]?.id}
          </span>
        </Link>
      ) : (
        <span className="MuiTypography-root MuiTypography-body2">
          {twoTimes
            ? props.record[subClass]?.get(subClass2).get(subValue2)
            : linkName}
        </span>
      )}
    </>
  );
};

export { PointerField };
