/* eslint-disable eqeqeq */
import React from "react";
import { getTxScannerURL, getAddressScannerURL } from "../contracts";

export function Hash({ hash, shorten, clickable, mode, date }) {
  let href;
  if (mode === 'address') href = getAddressScannerURL(hash)
  if (mode === 'tx' || mode === 'date') href = getTxScannerURL(hash)

  if (!hash) return (<span>{'-'}</span>)
  else if (!shorten || !mode) return (<span>{hash}</span>);
  else if (!isNaN(shorten)) {
    return (
      <>
        {
          clickable &&
          (((mode === 'address' || mode === 'tx') && hash != 0) &&
            <p> <a href={href} target='_blank' rel="noreferrer"> [{hash?.slice(0, shorten)}...{hash?.slice(-shorten)}]</a></p>
          )
        }
        {
          !clickable &&
          ((mode === 'address' || mode === 'tx') &&
            <> [ {hash?.slice(0, shorten)} ... {hash?.slice(-shorten)} ]</>
          )
        }
        {
          clickable &&
          ((mode === 'date' && hash != 0) &&
            <a href={href} target='_blank' rel="noreferrer"> {date?.toLocaleString("en-US")}</a>)
        }
      </>
    );
  }
}