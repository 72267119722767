import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  NumberField,
  useNotify,
  useRefresh,
} from "react-admin";
import Parse from "parse";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import EditList from "./EditList";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateTokenRate, updateTokenPrice } from "../../contracts";
import { MetamaskInteraction } from "../../components/metamask/MetamaskInteraction";
import { MTextField } from "../../components";
import { CustomPagination } from "../../theme/customs/CustomPagination";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfigList = (props) => {
  const [update, setUpdate] = useState(0);
  const [listValue, setListValue] = useState([]);

  useEffect(() => {}, [update, listValue]);

  function passToParent(instanceRecord) {
    setListValue(instanceRecord.listValue);
  }

  return (
    <List
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      {...props}
      perPage={50}
      sort={{ field: "createdAt", order: "ASC" }}
      exporter={false}
    >
      <Datagrid>
        <TextField source="variable" label="ID" />
        <TextField source="label" label="Label" />
        <FunctionField
          label="Value"
          render={(record) =>
            record.type === "text" ? (
              <TextField
                id={record.id}
                data-type="textValue"
                source="textValue"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "400px",
                  display: "block",
                }}
              />
            ) : record.type === "md" ? (
              <TextField
                id={record.id}
                data-type="mdValue"
                source="mdValue"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "400px",
                  display: "block",
                }}
              />
            ) : record.type === "number" ? (
              <NumberField
                id={record.id}
                data-type="numberValue"
                source="numberValue"
              />
            ) : record.type === "boolean" && record.booleanValue ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <CheckIcon data-type="booleanValue" /> Yes
              </div>
            ) : record.type === "boolean" && !record.booleanValue ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <CloseIcon data-type="booleanValue" /> No
              </div>
            ) : record.type === "list" ? (
              <>
                {record.listValue?.map((item, index) => {
                  if (record.variable === "ico-restricted-countries") {
                    return item === Object(item) ? (
                      <>
                        {" "}
                        <Chip
                          style={{ margin: "5px 5px" }}
                          key={index}
                          label={<p> level-{index} </p>}
                        />{" "}
                      </>
                    ) : (
                      <Chip
                        style={{ marginRight: "10px" }}
                        key={index}
                        label={
                          item.length < 20 ? item : item.substr(0, 20) + "..."
                        }
                      />
                    );
                  }
                })}
                {listValue?.map((item, index) => {
                  if (index !== 0 && record.variable === "level-requirements") {
                    return item === Object(item) ? (
                      <>
                        {" "}
                        <Chip
                          style={{ margin: "5px 5px" }}
                          key={index}
                          label={<p> level-{index} </p>}
                        />{" "}
                      </>
                    ) : (
                      <Chip
                        style={{ marginRight: "10px" }}
                        key={index}
                        label={
                          item.length < 20 ? item : item.substr(0, 20) + "..."
                        }
                      />
                    );
                  }
                })}
              </>
            ) : record.type === "md" ? (
              <TextField
                id={record.id}
                data-type="mdValue"
                source="mdValue"
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "400px",
                  display: "block",
                }}
              />
            ) : record.type === "object" ? (
              <>
                {Object.keys(record.objectValue).map((el) => {
                  return (
                    <span style={{ marginRight: "10px" }}>
                      {el}:{record.objectValue[el]}
                    </span>
                  );
                })}
              </>
            ) : null
          }
        />
        <CustomEditBtn
          update={update}
          setUpdate={setUpdate}
          passToParent={passToParent}
        />
      </Datagrid>
    </List>
  );
};

const CustomEditBtn = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const { record, resource, setUpdate, passToParent, update } = props;

  const [open, setOpen] = useState(false);
  const [textValue, setTextValue] = useState(null);
  const [numberValue, setNumberValue] = useState(null);
  const [booleanValue, setBooleanValue] = useState(null);
  const [listValue, setListValue] = useState(null);
  const [mdValue, setMdValue] = useState(null);
  const [txLoading, setTxLoading] = useState(false);
  const [txLoadingPrice, setTxLoadingPrice] = useState(false);
  const [configLevel, setConfigLevel] = useState(null);
  const [objectValue, setObjectValue] = useState(null);

  useEffect(() => {
    setTextValue(record?.textValue ? record.textValue : "");
    setMdValue(record?.mdValue ? record.mdValue : "");
    setNumberValue(record?.numberValue ? record.numberValue : 0);
    setBooleanValue(record?.booleanValue ? record.booleanValue : false);
    setListValue(record?.listValue ? record.listValue : []);
    setObjectValue(record?.objectValue ? record.objectValue : {});
    //eslint-disable-next-line
  }, [record]);

  function handleClickOpen() {
    setOpen(true);
    setTextValue(record.textValue);
    setNumberValue(record.numberValue);
    setBooleanValue(record.booleanValue);
    setListValue(record.listValue);
    setMdValue(record.mdValue);
    setObjectValue(record.objectValue);
  }

  function handleClose() {
    setOpen(false);
    setUpdate(update + 1);
    setTimeout(() => {
      refresh();
    }, 500);
  }

  function handleListValue(data = []) {
    setListValue(data);
  }

  async function handleSave(txMode) {
    let valuePost;
    if (record.type === "text") {
      valuePost = textValue;
    } else if (record.type === "boolean") {
      valuePost = booleanValue;
    } else if (record.type === "number") {
      valuePost = +numberValue;
    } else if (record.type === "list") {
      valuePost = listValue;
    } else if (record.type === "md") {
      valuePost = mdValue;
    } else if (record.type === "object") {
      valuePost = objectValue;
    }
    if (record.variable === "level-requirements") {
      return handleClose();
    }

    try {
      const query = new Parse.Query(resource);
      const res = await query.get(record.id);
      if (record.variable === "token-price") {
        if (txMode === "update-token-price") {
          setTxLoadingPrice(true);
          await updateTokenPrice(valuePost);
          res.set(`${record.type}Value`, valuePost);
          res
            .save()
            .then((res) => {
              notify(`Changes saved`);
            })
            .catch((err) => {
              notify(`Error: ${err.message}`, { type: "error" });
            });
          setTxLoadingPrice(false);
        } else if (txMode === "update-token-rate") {
          setTxLoading(true);
          await updateTokenRate(valuePost);
          res.set(`${record.type}Value`, valuePost);
          res
            .save()
            .then((res) => {
              notify(`Changes saved`);
            })
            .catch((err) => {
              notify(`Error: ${err.message}`, { type: "error" });
            });
          setTxLoading(false);
        }
      } else {
        res.set(`${record.type}Value`, valuePost);
        await res.save();
        refresh();
        notify(`Changes saved`);
      }
      if (txMode === "update-token-price" || txMode === "update-token-rate") {
      } else {
        setOpen(false);
      }
    } catch (err) {
      console.log("err", err);
      notify(`Error: ${err.message}`, { type: "error" });
      handleClose();
    }
  }

  function handleSaveLevelsConfig(config) {
    setConfigLevel(config);
  }

  function handleSaveLevels() {
    if (configLevel) {
      configLevel
        .save()
        .then((r) => notify("Change saved", { type: "success" }))
        .catch((err) => {
          notify("Error Occurred", { type: "error" });
        });
      handleClose();
    }
    handleClose();
  }

  function handleChangeObject(value, key) {
    setObjectValue((pre) => ({
      ...pre,
      [key]: +value,
    }));
  }

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {" "}
          {record.label}{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {record.type === "number" ? (
              <input
                type="number"
                className="number-input"
                value={numberValue}
                onChange={(e) => setNumberValue(e.target.value)}
              />
            ) : null}
            {record.type === "boolean" ? (
              <>
                No{" "}
                <Switch
                  checked={booleanValue}
                  onChange={(e) => setBooleanValue(e.target.checked)}
                  color="primary"
                  name="checkbox"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />{" "}
                Yes
              </>
            ) : null}
            {record.type === "text" ? (
              <label htmlFor={record.id}>
                {" "}
                <textarea
                  type="text"
                  className="input-txt"
                  value={textValue}
                  onChange={(e) => setTextValue(e.target.value)}
                />{" "}
              </label>
            ) : null}
            {record.type === "md" ? (
              <label htmlFor={record.id}>
                {" "}
                <textarea
                  type="text"
                  className="input-txt"
                  value={mdValue}
                  onChange={(e) => setMdValue(e.target.value)}
                />{" "}
              </label>
            ) : null}
            {record.type === "list" ? (
              <EditList
                func={handleListValue}
                record={record}
                passToParent={passToParent}
                handleSaveLevelsConfig={handleSaveLevelsConfig}
              />
            ) : null}{" "}
            {record.type === "object" ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {Object.keys(record.objectValue).map((el) => {
                  return (
                    <>
                      <span style={{ margin: "0 10px" }}>{el}</span>:{" "}
                      <MTextField
                        value={objectValue?.[el]}
                        onChange={(e) => handleChangeObject(e.target.value, el)}
                      />
                    </>
                  );
                })}
              </div>
            ) : null}
          </DialogContentText>
        </DialogContent>
        {record.variable === "level-requirements" ? (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button onClick={() => handleSaveLevels()} color="primary">
              Save
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            {record.type === "number" && record.variable === "token-price" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <MetamaskInteraction>
                  {" "}
                  <Button
                    onClick={() => handleSave("update-token-price")}
                    color="primary"
                  >
                    {txLoadingPrice ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Update token Price"
                    )}
                  </Button>{" "}
                  <Button
                    onClick={() => handleSave("update-token-rate")}
                    color="primary"
                  >
                    {txLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Update token rate"
                    )}
                  </Button>{" "}
                </MetamaskInteraction>
                <Button onClick={handleClose} color="primary">
                  Discard
                </Button>
              </div>
            ) : (
              <>
                <Button onClick={handleClose} color="primary">
                  Discard
                </Button>
                <Button onClick={handleSave} color="primary">
                  Save
                </Button>
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
      <span
        onClick={handleClickOpen}
        className="MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-6 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
        role="button"
        aria-disabled="false"
        aria-label="Edit"
      >
        <span className="MuiButton-label">
          <EditIcon className="MuiSvgIcon-root RaButton-smallIcon-9" />
          <span className="RaButton-label-7">Edit</span>
        </span>
      </span>
    </>
  );
};

export { ConfigList };
