import { web3Instance } from '../contracts/web3Instance'

const metaMaskConfigs = {
  '0x61': { // BSC TESTNET
    chain: {
      chainId: '0x61',
      chainName: 'BNB Chain [TESTNET]',
      nativeCurrency: {
        name: 'Binance',
        symbol: 'BNB', // 2-6 characters long
        decimals: 18
      },
      blockExplorerUrls: ['https://testnet.bscscan.com'],
      rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    },
    evolveToken: {
      address: '0x1550007c4a314a920031787a02a5821ca7e84664',
      symbol: 'EVOLVE',
      decimals: 18,
      // tokenImage: 'http://placekitten.com/200/300'
    },
  },
  '0x38': { // BSC MAIN
    chain: {
      chainId: '0x38',
      chainName: 'BNB Chain (BSC)',
      nativeCurrency: {
        name: 'Binance',
        symbol: 'BNB', // 2-6 characters long
        decimals: 18
      },
      blockExplorerUrls: ['https://bscscan.com'],
      rpcUrls: ['https://bsc-dataseed.binance.org/'],
    },
    evolveToken: {
      address: '0x33C91eb6d0CE1dd138f9E93bCE31Ac779dA4ee49',
      symbol: 'EVOLVE',
      decimals: 18,
      // tokenImage: 'http://placekitten.com/200/300'
    },
  },
  "0x89": { // POLYGON MAIN
    chain: {
      chainId: '0x89',
      chainName: 'Polygon Mainnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC', // 2-6 characters long
        decimals: 18
      },
      blockExplorerUrls: ['https://polygonscan.com'],
      rpcUrls: ['https://nd-169-654-787.p2pify.com/139611e9a2dac403ba155b89890c2f9c'],
    },
    evolveToken: {},
  },
  "0x13881": { // POLYGON TESTNET
    chain: {
      chainId: '0x13881',
      chainName: 'Polygon Testnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC', // 2-6 characters long
        decimals: 18
      },
      blockExplorerUrls: ['https://mumbai.polygonscan.com'],
      rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
    },
    evolveToken: {}
  },
  }

export const fromWei = (number) => {
    return web3Instance.utils.fromWei(number.toString(), 'ether');
  }

export const toWei = (number) => {
    return web3Instance.utils.toWei(number.toString(), 'ether');
  }

const metaMastConf = metaMaskConfigs[process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID]
export const { chain, evolveToken } = metaMastConf
