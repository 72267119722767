
import React, { useRef, useEffect } from 'react'
import Parse from 'parse'
import './threeWaysSwith.css'
import { useNotify } from 'react-admin'

function ThreeWaysSwith(props) {
  const { source, record, parseClass } = props
  const notify = useNotify()
  const trueButton = useRef(null)
  const falseButton = useRef(null)
  const offButton = useRef(null)

  const status = record[source]

  useEffect(() => {
    if (status === true) {
      trueButton.current.classList.add('selected')
    } else if (status === false) {
      falseButton.current.classList.add('selected')
    } else {
      offButton.current.classList.add('selected')
    }
    //eslint-disable-next-line
  }, [])

  const sourceParseClass = Parse.Object.extend(parseClass)
  const sourceObject = new sourceParseClass()
  sourceObject.id = record.id

  const handleClick = (e) => {
    if (e.target.id === 'true') {
      trueButton.current.classList.add('selected')
      falseButton.current.classList.remove('selected')
      offButton.current.classList.remove('selected')
      sourceObject.save({ [source]: true })
      notify('Successfully saved')
    } else if (e.target.id === 'false') {
      trueButton.current.classList.remove('selected')
      falseButton.current.classList.add('selected')
      offButton.current.classList.remove('selected')
      sourceObject.save({ [source]: false })
      notify('Successfully saved')
    } else if (e.target.id === 'off') {
      trueButton.current.classList.remove('selected')
      falseButton.current.classList.remove('selected')
      offButton.current.classList.add('selected')
      sourceObject.save({ [source]: null })
      notify('Successfully saved')
    }
  }

  return (
    <fieldset className='three-ways-switch'>
      <div>
        <label ref={falseButton} className='warning three-ways-switch'>
          Deny
          <input id="false" onClick={handleClick} name="state" type="radio" value="working" className='three-ways-switch' />
        </label>
        <label ref={offButton} className='off three-ways-switch'>
          System
          <input id="off" onClick={handleClick} name="state" type="radio" value="standby" className='three-ways-switch' />
        </label>
        <label ref={trueButton} className='three-ways-switch'>
          Allow
          <input id="true" onClick={handleClick} name="state" type="radio" value="off" className='three-ways-switch' />
        </label>
      </div>
    </fieldset>
  )
}

export { ThreeWaysSwith }

