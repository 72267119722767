import { useEffect, useState } from "react";
import Parse from "parse";
import { useNotify } from "react-admin";

const useReadData = (
  classField,
  objectId,
  includesList,
  customeField,
  value
) => {
  const notify = useNotify();
  const [data, setData] = useState(null);
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = new Parse.Query(classField);
        if (objectId) query.equalTo("objectId", objectId);
        if (includesList) query.include(includesList);
        if (customeField) query.equalTo(customeField, value);
        const res = await query.find();
        setData(res);
        setError(false);
      } catch (err) {
        console.log("here");
        setData("error occured", err);
        setError(true);
        notify(`Error: ${err.message}`, { type: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    //eslint-disable-next-line
  }, [classField]);
  return { data, error, loading };
};

export { useReadData };
