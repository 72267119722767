import React from "react";

import {
  List,
  Datagrid,
  DateField,
  ShowButton,
  FunctionField,
  TextField,
  SelectInput,
  TextInput,
  Link,
} from "react-admin";
// import { PointerField } from '../../components';
import { Hash } from "../../components/Hash";
import { FilterDate } from "../../components/FilterDate";
import { CustomTable } from "../../components";
import { CustomPagination } from "../../theme/customs/CustomPagination";
import { PointerField } from "../../components";

const columns = [
  {
    id: "createdAt",
    label: "Created at",
    type: "date",
  },
  {
    id: "calledFunction",
    label: "Called Function",
    type: "string",
  },
  {
    id: "status",
    label: "Status",
    type: "string",
  },
  {
    id: "transactionHash",
    label: "Transaction Hash",
    type: "address",
  },
  {
    id: "error",
    label: "Error",
    type: "error",
  },
  {
    id: "competition",
    label: "Competition",
    type: "simplePointer",
  },
  {
    id: "objectId",
    label: "Show",
    type: "linking",
    linking: "BlockchainLog",
  },
];

const BlockchainList = (props) => {
  const [change, setChange] = React.useState(false);
  const [data, setData] = React.useState(null);
  return (
    <>
      <FilterDate
        setChange={setChange}
        setData={setData}
        resourse="BlockchainLog"
      />
      {change ? (
        <CustomTable
          data={data}
          columns={columns}
          title={""}
          infinite={true}
          perPage={50}
        />
      ) : (
        <List
          pagination={<CustomPagination />}
          bulkActionButtons={false}
          filters={categoryFilters}
          {...props}
          perPage={50}
          sort={{ field: "createdAt", order: "DESC" }}
          exporter={false}
        >
          <Datagrid>
            <DateField showTime={true} source="createdAt" label="Created at" />
            <FunctionField
              label={"Called function"}
              render={(record) =>
                record.calledFunction ? (
                  <span style={{ color: "green" }}>
                    {record.calledFunction}
                  </span>
                ) : (
                  "-"
                )
              }
            />
            <TextField source="status" label="Status" />
            <FunctionField
              source="transactionHash"
              label="Transaction hash"
              render={(record) =>
                record.transactionHash ? (
                  <Hash
                    hash={record.transactionHash}
                    mode="tx"
                    clickable={true}
                    shorten={"5"}
                  />
                ) : (
                  <span>-</span>
                )
              }
            />
            <FunctionField
              label={"Error"}
              render={(record) =>
                record.error ? <span style={{ color: "red" }}>Error</span> : "-"
              }
            />
            <FunctionField
              label={"Competition"}
              render={(record) => (
                <Link
                  to={`/${"Competition"}/${record["competition"]?.id}/show`}
                >
                  {record["competition"]?.id}
                </Link>
              )}
            />
            <ShowButton />
          </Datagrid>
        </List>
      )}
    </>
  );
};

const categoryFilters = [
  <SelectInput
    alwaysOn
    source="status"
    label="Status"
    choices={[
      { id: "READY_TO_SEND_TX", name: "READY_TO_SEND_TX" },
      { id: "ERROR_BEFORE_SEGN", name: "ERROR_BEFORE_SEGN" },
      { id: "TX_SIGNED", name: "TX_SIGNED" },
      { id: "EVM_NETWORK_ERROR", name: "EVM_NETWORK_ERROR" },
      { id: "SUCCESS", name: "SUCCESS" },
    ]}
  />,
  <TextInput source="competition" alwaysOn />,
];
export { BlockchainList };
