import ParseClient from "./ParseClient";
import Parse from "parse";

const parseConfig = {
  URL: process.env.REACT_APP_PARSE_SERVER_URL,
  "APP-ID": process.env.REACT_APP_PARSE_APP_ID,
  "JS-KEY": process.env.REACT_APP_PARSE_JAVASCRIPT_KEY,
};

Parse.serverURL = parseConfig.URL;
Parse.initialize(parseConfig["APP-ID"], parseConfig["JS-KEY"]);

export const dataProvider = ParseClient({
  URL: parseConfig.URL,
  JAVASCRIPT_KEY: parseConfig["JS-KEY"],
  APP_ID: parseConfig["APP-ID"],
});
