import {
  pointerHandler,
  numberHandler,
  booleanHandler,
  objectHandler,
  stringHandler,
  arrayHandler,
} from "../edit/typeHandlers";

function configCreateFields(field) {
  switch (field) {
    case "CompetitionPreset":
      return competitionPreset;
    case "Test":
      break;
    default:
      break;
  }
}

const competitionPreset = [
  {
    name: "game",
    type: "pointer",
    typeHandler: pointerHandler,
  },
  {
    name: "feeInUSD",
    type: "number",
    typeHandler: numberHandler,
  },
  {
    name: "players",
    type: "number",
    typeHandler: numberHandler,
  },
  {
    name: "promotion",
    type: "string",
    typeHandler: stringHandler,
  },
  {
    name: "settings",
    type: "object",
    typeHandler: objectHandler,
  },
  {
    name: "description",
    type: "string",
    typeHandler: stringHandler,
  },
  {
    name: "active",
    type: "boolean",
    typeHandler: booleanHandler,
  },
  {
    name: "KYCRequired",
    type: "boolean",
    typeHandler: booleanHandler,
  },
  {
    name: "comingSoon",
    type: "boolean",
    typeHandler: booleanHandler,
  },
  {
    name: "levelRequired",
    type: "number",
    typeHandler: numberHandler,
  },
  {
    name: "rakeInUsd",
    type: "number",
    typeHandler: numberHandler,
  },
  // {
  //   name: "_supportedTokens",
  //   type: "array",
  //   typeHandler: arrayHandler,
  // },
  {
    name: "currency",
    type: "string",
    typeHandler: stringHandler,
  },
];

export { configCreateFields };
