export {
  updateTokenRate,
  evolveFactoryContract,
  addNewPreset,
  updatePreset,
} from "./factory";
export { updateTokenPrice } from "./market";
export { lockTokens } from "./vesting";

export const scannerURL = "https://polygonscan.com";
export const getTokenScannerURL = (contractAddress) =>
  `${scannerURL}/token/${contractAddress}`;
export const getAddressScannerURL = (address) =>
  `${scannerURL}/address/${address}`;
export const getTxScannerURL = (txHash) => `${scannerURL}/tx/${txHash}`;
