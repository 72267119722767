const CustomSaveBtn = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      class="MuiButtonBase-root MuiButton-root MuiButton-contained RaSaveButton-button-69 MuiButton-containedPrimary"
      tabindex="0"
      type="button"
      aria-label="Save"
    >
      <span class="MuiButton-label">
        <svg
          class="MuiSvgIcon-root RaSaveButton-leftIcon-70 RaSaveButton-icon-71"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"></path>
        </svg>
        Save
      </span>
    </button>
  );
};

export { CustomSaveBtn };
