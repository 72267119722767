import React from "react";

import {
  List,
  Datagrid,
  DateField,
  ShowButton,
  FunctionField,
  TextField,
  SelectInput,
  Link,
} from "react-admin";
import { PointerField, CustomTable } from "../../components";
import { FilterDate } from "../../components/FilterDate";
import { CustomPagination } from "../../theme/customs/CustomPagination";

const columns = [
  {
    id: "createdAt",
    label: "Created at",
    type: "date",
  }, {
    id: "user",
    label: "User",
    type: "pointer",
  },
  {
    id: "status",
    label: "Status",
    type: "string",
  },
  {
    id: "startedAt",
    label: "Started at",
    type: "date",
  },
  {
    id: "finishedAt",
    label: "Finished at",
    type: "date",
  },
  {
    id: "sideScores",
    label: "Winner",
    type: "special0",
  },
  {
    id: "objectId",
    label: "API Call Logs",
    type: "special1",
  },
  {
    id: "objectId",
    label: "Show",
    type: "linking",
    linking: "Competition",
  },
];

const LedgerTransactionList = (props) => {
  const [change, setChange] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [selectedDates, setSelectedDates] = React.useState(null);

  const exportCompetitionExcel = async () => {
    // const result = await Parse.Cloud.run("export-competitions", {
    //   datePeriod: selectedDates,
    // })
    // const url = result._url;
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", "CompetitionsReport.xlsx");
    // document.body.appendChild(link);
    // link.click();
    // link.parentNode.removeChild(link);
  };

  return (
    <>
      <FilterDate
        setChange={setChange}
        setData={setData}
        resourse="Transaction"
        selectedDates={setSelectedDates}
      />
      {change ? (
        <CustomTable
          data={data}
          columns={columns}
          title={""}
          infinite={true}
          perPage={50}
        />
      ) : (
        <List
          pagination={<CustomPagination />}
          bulkActionButtons={false}
          filters={categoryFilters}
          {...props}
          perPage={50}
          sort={{ field: "createdAt", order: "DESC" }}
          // exporter={exportCompetitionExcel}
          exporter={false}
        >
          <Datagrid>
            {
              <FunctionField
                label="User"
                render={(record) => (
                  <Link
                    to={`/${"User"}/${record["user"]?.id}/show`}
                  >
                    <span>
                      <PointerField
                        record={record}
                        reference="Transaction"
                        subClass="user"
                        subValue="username"
                      />{" "}
                    </span>
                  </Link>
                )}
              />
            }

            <DateField showTime={true} source="createdAt" label="Created at" />
            <TextField source="status" label="Status" />
            <TextField source="type" label="Type" />
            <TextField source="amount" label="Amount" />
            <TextField source="transactionState" label="Transaction State" />
            {/* <ShowButton /> */}
          </Datagrid>
        </List>
      )}
    </>
  );
};

const categoryFilters = [
  <SelectInput
    alwaysOn
    source="type"
    label="Type"
    choices={[
      { id: "deposit", name: "deposit" },
      { id: "withdraw", name: "withdraw" },
      { id: "transfer", name: "transfer" },
      { id: "startCompetition", name: "startCompetition" },
      { id: "finishCompetition", name: "finishCompetition" },
    ]}
  />,
];
export { LedgerTransactionList };
