import { toWei } from "../../utils/metamaskHelper";
import { Web3, web3Instance } from "../web3Instance";
const ABI = [
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "_ercToken",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "_tokenList",
    outputs: [
      {
        internalType: "contract ERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract ERC20",
        name: "_ERCToken",
        type: "address",
      },
    ],
    name: "addNewToken",
    outputs: [
      {
        internalType: "uint256",
        name: "_tokenIndex",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_evolveAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_tokenIndex",
        type: "uint256",
      },
    ],
    name: "swapTokenToEvolve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenMarket",
    outputs: [
      {
        internalType: "contract ERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenPriceWithUSD",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_newPriceWithUSD",
        type: "uint256",
      },
    ],
    name: "updateTokenPrice",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const marketContractAddress = "0x3d293425DBf94749cd5f68E94DF2b3aa114a8125";
const evolveMarketContract = new web3Instance.eth.Contract(
  ABI,
  marketContractAddress
);

const updateTokenPrice = async (newRate) => {
  const [account] = await window.ethereum?.request({ method: "eth_accounts" });
  const updateTokenPriceMethod = evolveMarketContract.methods.updateTokenPrice(
    toWei(newRate)
  );
  const transactionObject = {
    from: Web3.utils.toChecksumAddress(account),
    to: Web3.utils.toChecksumAddress(marketContractAddress),
  };
  return new Promise((resolve, reject) => {
    updateTokenPriceMethod
      .send(transactionObject)
      .on("receipt", (result) => {
        resolve(result);
        console.log("result", result);
      })
      .on("error", (error) => {
        console.log(error);
        reject(error);
      });
  });
};

export { updateTokenPrice, evolveMarketContract };
