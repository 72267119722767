import { Card, CardHeader } from "@material-ui/core";
import { useEffect } from "react";
import { useRedirect } from "react-admin";
const Dashboard = () => {
  const redirect = useRedirect();
  useEffect(() => {
    redirect("/User");
  }, []);
  return (
    <Card>
      <CardHeader title="Welcome to the administration" />
    </Card>
  );
};

export { Dashboard };
