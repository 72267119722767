import React from "react";
import { Link } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Hash } from "../components/Hash";

const CustomTable = (props) => {
  const { data, columns, title, model, perPage, pageHandler } = props;

  console.log("data is", data?.length);

  const useStyles = makeStyles({
    root: {
      width: "100%",
      marginTop: title ? "5px" : "70px",
      marginBottom: "25px",
    },
    container: {},
    MuiTableCell: {
      padding: 20,
    },
    customPadding: {
      padding: `6px 24px 6px 16px`,
    },
    center: {
      textAlign: "center",
    },
  });

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(perPage ? perPage : 50);

  const handleChangePage = (event, newPage) => {
    if (pageHandler) {
      pageHandler(newPage);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {title ? <h2 className={classes.center}>{title}</h2> : null}
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell className={classes.customPadding} key={column.id}>
                    {" "}
                    {column.label}{" "}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        let value;
                        if (model === "cloudFunc") {
                          value = row[column.id];
                        } else {
                          value = row.attributes[column.id];
                        }
                        if (
                          column.type === "competitorStatusPointer" ||
                          column.type === "linking" ||
                          column.type === "special1" ||
                          column.type === "special2"
                        )
                          value = row.id;
                        return (
                          <TableCell
                            key={column.id}
                            className={classes.customPadding}
                          >
                            {column.type === "linking" ? (
                              <Link to={`/${column.linking}/${value}/show`}>
                                {"Show"}
                              </Link>
                            ) : null}
                            {column.type === "number" ? value : null}

                            {column.type === "error" ? (
                              value ? (
                                <span style={{ color: "red" }}>Error</span>
                              ) : (
                                "-"
                              )
                            ) : null}
                            {column.type === "simplePointer" ? (
                              <>
                                <span>{value?.id}</span>
                              </>
                            ) : null}
                            {column.type === "pointers" ? (
                              value?.id ? (
                                <Link
                                  to={`/${value.className}/${value?.id}/show`}
                                >
                                  {value?.id}
                                </Link>
                              ) : (
                                "BOT"
                              )
                            ) : null}
                            {column.type === "pointer" ? (
                              value?.id ? (
                                <Link to={`/User/${value?.id}/show`}>
                                  {value?.get("username")}
                                </Link>
                              ) : (
                                "BOT"
                              )
                            ) : null}
                            {column.type === "date"
                              ? value?.toLocaleString("en-US")
                              : null}
                            {column.type === "dateNumber"
                              ? new Date(value)?.toLocaleString("en-US")
                              : null}
                            {column.type === "boolean" ? (
                              value ? (
                                <CheckIcon />
                              ) : (
                                <CloseIcon />
                              )
                            ) : null}
                            {column.type === "string" ? `${value}` : null}
                            {column.type === "address" ? (
                              <Hash
                                hash={value}
                                clickable={true}
                                mode="address"
                                shorten={5}
                              />
                            ) : null}
                            {column.type === "competitorStatusPointer" ? (
                              <Link to={`/CompetitorStatus/${value}/show`}>
                                {"Show"}
                              </Link>
                            ) : null}
                            {column.type === "kills" ? value?.kills : null}
                            {/* the special field are down blow */}

                            {column.type === "special0"
                              ? value &&
                                value?.[0] &&
                                value?.[0]?.score !== undefined &&
                                value?.[1] &&
                                value?.[1]?.score !== undefined
                                ? value[0].score > value[1].score
                                  ? `Side ${value[0].side}`
                                  : value[0].score < value[1].score
                                  ? `Side ${value[1].side}`
                                  : "TIE"
                                : null
                              : null}
                            {column.type === "special1" ? (
                              <Link
                                to={`/APICallLog?displayedFilters=%7B%7D&filter=%7B"competition"%3A"${value}"%7D&order=ASC&page=1&perPage=50&sort=competition`}
                              >
                                API Call Logs
                              </Link>
                            ) : null}
                            {column.type === "special2" ? (
                              <Link
                                to={`/BlockchainLog?displayedFilters=%7B%7D&filter=%7B"competition"%3A"${value}"%7D&order=ASC&page=1&perPage=50&sort=competition`}
                              >
                                Blockchain Logs
                              </Link>
                            ) : null}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {console.log(rowsPerPage, "rowsPerPage")}
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={data ? data.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export { CustomTable };
