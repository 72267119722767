import TextField from "@material-ui/core/TextField";

const MTextField = ({ label, onChange, id, value }) => {
  return (
    <TextField
      id={id}
      label={label}
      variant="outlined"
      onChange={onChange}
      value={value}
    />
  );
};

export { MTextField };
