import {
  TopToolbar,
  // EditButton
} from "react-admin";
// import { DeleteBtn } from "../../components";

const CustomTopToolbar = ({ basePath, data, resource }) => {

  return (
    <TopToolbar>
      {/* <EditButton basePath={basePath} record={data} label="Edit" /> */}
      {/* <DeleteBtn basePath={basePath} data={data} resource={resource} /> */}
      {/* <DeleteButton basePath={basePath} record={data} label="Delete" /> */}
    </TopToolbar>
  )
};



export { CustomTopToolbar }