import React, { useEffect, useState } from 'react'
import MetaMaskOnboarding from '@metamask/onboarding';
import { chain } from '../../utils/metamaskHelper'
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(3),
    },
  },
}));

function Metamask(props) {
  const [isMetaMaskExists, setIsMetaMaskExists] = useState(MetaMaskOnboarding.isMetaMaskInstalled())
  const [account, setAccount] = useState(false)
  const [chainId, setChainId] = useState(false)
  const [connectWalletLoading, setConnectWalletLoading] = useState(false)
  const [changeChainLoading, setChangeChainLoading] = useState(false)
  const notify = useNotify();
  let ethereum

  // initial provider in phones
  if (window.ethereum) {
    handleEthereum();
  } else {
    window.addEventListener('ethereum#initialized', handleEthereum, {
      once: true,
    });
    setTimeout(handleEthereum, 3000); // 3 seconds
  }

  function handleEthereum() {
    if (window.ethereum && window.ethereum.isMetaMask) {
      ethereum = window.ethereum
    } else {
      console.log('Please install MetaMask!');
    }
  }

  const {
    connectToMetamask,
    buttonClassName,
    display,
    changedChainId
  } = props



  useEffect(() => {
    isMetaMaskInstalled()
    if (isMetaMaskExists) {
      (async () => {
        await recognizeChainChange()
        const [account] = await window.ethereum?.request({ method: 'eth_accounts' })
        await setAccount(account)
        window.ethereum?.on('accountsChanged', async (accounts) => {
          if (!accounts.length) props.ready(false)
          await setAccount(accounts[0])
        });
      })()
    }
    //eslint-disable-next-line
  }, [])


  useEffect(() => {
    (async () => {
      const id = await recognizeChainId()
      if (id !== chainId) {
        setChainId(id)
      }
      // await initWallet()
      if (isMetaMaskExists && chainId === process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID && account) {
        props.ready(true)
      }
      else props.ready(false)
    })()
    //eslint-disable-next-line
  }, [changedChainId, connectWalletLoading, account, chainId]);


  const isMetaMaskInstalled = () => {
    const result = MetaMaskOnboarding.isMetaMaskInstalled()
    setIsMetaMaskExists(result)
  }

  const recognizeChainChange = async () => {
    await ethereum?.on('chainChanged', async () => {
      const currentChainId = await ethereum?.request({ method: 'eth_chainId' })
      if (isMetaMaskExists && currentChainId === process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID && account) props.ready(true)
      else props.ready(false)
    });
  }

  const recognizeChainId = async () => {
    try {
      const currentChainId = await ethereum?.request({ method: 'eth_chainId' })
      return currentChainId
    }
    catch (error) {
      console.log(error)
      notify(error.message, 'error')
    }
  }

  // const initWallet = async () => {
  //   try {
  //     // await setUserAccountAddress()
  //     // account && await getUserEvolveBalance(account)
  //   }
  //   catch (error) {
  //     notify('error', error.message)
  //     console.log(error)
  //   }
  // }

  const changeChain = async () => {
    try {
      const id = await recognizeChainId()
      if (id !== process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID) {
        setChangeChainLoading(true)
        await addChain() // If it wasn't the specific chain
        await ethereum?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID }], // chainId must be in hexadecimal numbers // BNB TESTNET = 0x61 // BNB MAINNET = 0x38 // ETH MAINNET = 0x1
        })
        if (isMetaMaskExists && id === process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID && account) props.ready(true)
      }
    }
    catch (error) {
      setChangeChainLoading(false)
      console.log(error)
      if (error.code === -32002) {
        notify('Request is pending, please open MetaMask.', 'error')
        console.log('Request is pending, please open MetaMask.')
        setChangeChainLoading(true)
      }
      props.ready(false)
    }
  }

  const addChain = async () => {
    try {
      await ethereum?.request({
        method: 'wallet_addEthereumChain',
        params: [chain],
      });
    } catch (error) {
      console.error(error);
    }
  }

  const connectWallet = async () => {
    setConnectWalletLoading(true)
    try {
      await ethereum?.request({
        "id": 1,
        "jsonrpc": "2.0",
        "method": "wallet_requestPermissions",
        "params": [
          {
            "eth_accounts": {}
          }
        ]
      });
      await connectToMetamask()
      const id = await recognizeChainId()
      setChainId(id)
      setConnectWalletLoading(false)
    }
    catch (error) {
      if (error.code === -32002) {
        try {
          await connectToMetamask()
          const id = await recognizeChainId()
          setChainId(id)
          setConnectWalletLoading(false)
        } catch (error) {
          console.log(error)
        }
      }
      setConnectWalletLoading(false)
      console.error(error)
    }
  }

  const classes = useStyles();

  return (
    <div className={display}>
      {!isMetaMaskExists &&
        <InstallWalletDialog />
      }
      {isMetaMaskExists && !account &&
          < Button size="small" variant="contained" className={buttonClassName} onClick={connectWallet} color="primary" >
          {connectWalletLoading ?
            <span>	&nbsp; Connecting... </span> :
            'Connect Wallet'}
            {/* <Button variant="contained" onClick={handleInstallMetamask} color="primary" >Install Metamask</Button> */}
        </Button>
      }
      {
        isMetaMaskExists && account && chainId !== process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID &&
        <div className={classes.root} >
          < Button variant="contained" className={buttonClassName} onClick={changeChain} color="primary" disabled={changeChainLoading} >
            {changeChainLoading ?
              <span disabled >Pending . . . </span>
              : 'change network'}
          </Button>
        </div>
      }
    </div>
  )
}


const InstallWalletDialog = (props) => {
  const onboarding = new MetaMaskOnboarding();
  // const classes = useStyles();
  useEffect(() => {
    return () => {
      onboarding.stopOnboarding()
    }
    //eslint-disable-next-line
  }, [])

  function handleInstallMetamask() {
    onboarding.startOnboarding();
  }
  return (
    <Button size="small"  variant="contained" onClick={handleInstallMetamask} color="primary" >Install Metamask</Button>
  )
}


export { Metamask }
