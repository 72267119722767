import { List, Datagrid, TextField } from "react-admin";
import { UpdateSwitchBtn } from "../../components";
import { CustomPagination } from "../../theme/customs/CustomPagination";

const GameList = (props) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      perPage={50}
      pagination={<CustomPagination />}
      exporter={false}
    >
      <Datagrid>
        <TextField source="title" />
        <UpdateSwitchBtn field="active" label="Active" />
      </Datagrid>
    </List>
  );
};

export { GameList };
