import { toWei } from "../../utils/metamaskHelper";
import { Web3, web3Instance } from "../web3Instance";
const factoryContractAddress = "0x64549CC4C170B63C7Adc77d4f70E098d6aFE42ae";

const ABI =[
  {
     "inputs": [],
     "stateMutability": "nonpayable",
     "type": "constructor"
  },
  {
     "anonymous": false,
     "inputs": [
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "competitionId",
           "type": "uint256"
        },
        {
           "indexed": false,
           "internalType": "uint8",
           "name": "teamSize",
           "type": "uint8"
        },
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "presetId",
           "type": "uint256"
        },
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "createAt",
           "type": "uint256"
        }
     ],
     "name": "NewCompetitionCreated",
     "type": "event"
  },
  {
     "anonymous": false,
     "inputs": [
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "presetId",
           "type": "uint256"
        },
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "entryFeeInUSD",
           "type": "uint256"
        },
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "numberOfTeamMemebr",
           "type": "uint256"
        },
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "rakeAmountInUSD",
           "type": "uint256"
        }
     ],
     "name": "NewPresetCreated",
     "type": "event"
  },
  {
     "anonymous": false,
     "inputs": [
        {
           "indexed": true,
           "internalType": "address",
           "name": "previousOwner",
           "type": "address"
        },
        {
           "indexed": true,
           "internalType": "address",
           "name": "newOwner",
           "type": "address"
        }
     ],
     "name": "OwnershipTransferred",
     "type": "event"
  },
  {
     "anonymous": false,
     "inputs": [
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "tokenIndex",
           "type": "uint256"
        },
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "lockAmount",
           "type": "uint256"
        },
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "newTotalLock",
           "type": "uint256"
        }
     ],
     "name": "PoolLocked",
     "type": "event"
  },
  {
     "anonymous": false,
     "inputs": [
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "tokenIndex",
           "type": "uint256"
        },
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "unlockAmount",
           "type": "uint256"
        },
        {
           "indexed": false,
           "internalType": "uint256",
           "name": "newTotalLock",
           "type": "uint256"
        }
     ],
     "name": "PoolUnlocked",
     "type": "event"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "_presetId",
           "type": "uint256"
        },
        {
           "components": [
              {
                 "components": [
                    {
                       "internalType": "address",
                       "name": "account",
                       "type": "address"
                    },
                    {
                       "internalType": "uint256",
                       "name": "tokenIndex",
                       "type": "uint256"
                    },
                    {
                       "internalType": "uint256",
                       "name": "payableInUSD",
                       "type": "uint256"
                    }
                 ],
                 "internalType": "struct Competitor[]",
                 "name": "competitors",
                 "type": "tuple[]"
              }
           ],
           "internalType": "struct Team[]",
           "name": "_teams",
           "type": "tuple[]"
        },
        {
           "internalType": "uint256",
           "name": "_createAt",
           "type": "uint256"
        }
     ],
     "name": "addNewCompetition",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "_entryFeeInUSD",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "_numberOfTeamMemebr",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "_createAt",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "_rakeAmountInUSD",
           "type": "uint256"
        }
     ],
     "name": "addNewPreset",
     "outputs": [
        {
           "internalType": "uint256",
           "name": "presetId",
           "type": "uint256"
        }
     ],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "contract IERC20",
           "name": "_tokenAddress",
           "type": "address"
        },
        {
           "internalType": "uint256",
           "name": "_stablePrice",
           "type": "uint256"
        }
     ],
     "name": "addToken",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "",
           "type": "uint256"
        }
     ],
     "name": "competitionList",
     "outputs": [
        {
           "internalType": "uint8",
           "name": "teamSize",
           "type": "uint8"
        },
        {
           "internalType": "enum CompetitionStatus",
           "name": "status",
           "type": "uint8"
        },
        {
           "internalType": "enum CompetitionWinner",
           "name": "winnerTeam",
           "type": "uint8"
        },
        {
           "internalType": "uint256",
           "name": "presetId",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "createAt",
           "type": "uint256"
        }
     ],
     "stateMutability": "view",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "_index",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "_amount",
           "type": "uint256"
        }
     ],
     "name": "depositToken",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "_index",
           "type": "uint256"
        },
        {
           "internalType": "contract IERC20",
           "name": "_newTokenAddress",
           "type": "address"
        }
     ],
     "name": "editTokenAddress",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [],
     "name": "getActiveTokens",
     "outputs": [
        {
           "components": [
              {
                 "internalType": "contract IERC20",
                 "name": "tokenAddress",
                 "type": "address"
              },
              {
                 "internalType": "uint256",
                 "name": "stablePrice",
                 "type": "uint256"
              },
              {
                 "internalType": "uint256",
                 "name": "totalBalance",
                 "type": "uint256"
              },
              {
                 "internalType": "uint256",
                 "name": "lockedBalance",
                 "type": "uint256"
              },
              {
                 "internalType": "bool",
                 "name": "isActive",
                 "type": "bool"
              }
           ],
           "internalType": "struct Token[]",
           "name": "",
           "type": "tuple[]"
        }
     ],
     "stateMutability": "view",
     "type": "function"
  },
  {
     "inputs": [],
     "name": "getAllTokens",
     "outputs": [
        {
           "components": [
              {
                 "internalType": "contract IERC20",
                 "name": "tokenAddress",
                 "type": "address"
              },
              {
                 "internalType": "uint256",
                 "name": "stablePrice",
                 "type": "uint256"
              },
              {
                 "internalType": "uint256",
                 "name": "totalBalance",
                 "type": "uint256"
              },
              {
                 "internalType": "uint256",
                 "name": "lockedBalance",
                 "type": "uint256"
              },
              {
                 "internalType": "bool",
                 "name": "isActive",
                 "type": "bool"
              }
           ],
           "internalType": "struct Token[]",
           "name": "",
           "type": "tuple[]"
        }
     ],
     "stateMutability": "view",
     "type": "function"
  },
  {
     "inputs": [],
     "name": "operator",
     "outputs": [
        {
           "internalType": "address",
           "name": "",
           "type": "address"
        }
     ],
     "stateMutability": "view",
     "type": "function"
  },
  {
     "inputs": [],
     "name": "owner",
     "outputs": [
        {
           "internalType": "address",
           "name": "",
           "type": "address"
        }
     ],
     "stateMutability": "view",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "",
           "type": "uint256"
        }
     ],
     "name": "presetList",
     "outputs": [
        {
           "internalType": "uint256",
           "name": "entryFeeInUSD",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "numberOfTeamMemebr",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "date",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "createAt",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "rakeAmountInUSD",
           "type": "uint256"
        },
        {
           "internalType": "bool",
           "name": "isActive",
           "type": "bool"
        }
     ],
     "stateMutability": "view",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "_index",
           "type": "uint256"
        }
     ],
     "name": "removeToken",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [],
     "name": "renounceOwnership",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "_competitionId",
           "type": "uint256"
        },
        {
           "internalType": "enum CompetitionWinner",
           "name": "_winnerTeam",
           "type": "uint8"
        }
     ],
     "name": "setCompetitionWinner",
     "outputs": [
        {
           "internalType": "bool",
           "name": "",
           "type": "bool"
        }
     ],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [],
     "name": "startCompetitionId",
     "outputs": [
        {
           "internalType": "uint256",
           "name": "",
           "type": "uint256"
        }
     ],
     "stateMutability": "view",
     "type": "function"
  },
  {
     "inputs": [],
     "name": "startPresetId",
     "outputs": [
        {
           "internalType": "uint256",
           "name": "",
           "type": "uint256"
        }
     ],
     "stateMutability": "view",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "",
           "type": "uint256"
        }
     ],
     "name": "tokens",
     "outputs": [
        {
           "internalType": "contract IERC20",
           "name": "tokenAddress",
           "type": "address"
        },
        {
           "internalType": "uint256",
           "name": "stablePrice",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "totalBalance",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "lockedBalance",
           "type": "uint256"
        },
        {
           "internalType": "bool",
           "name": "isActive",
           "type": "bool"
        }
     ],
     "stateMutability": "view",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "address",
           "name": "newOwner",
           "type": "address"
        }
     ],
     "name": "transferOwnership",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "address",
           "name": "_newOperator",
           "type": "address"
        }
     ],
     "name": "updateOperator",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "_presetId",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "_entryFeeInUSD",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "_numberOfTeamMemebr",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "_createAt",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "_rakeAmountInUSD",
           "type": "uint256"
        },
        {
           "internalType": "bool",
           "name": "_isActive",
           "type": "bool"
        }
     ],
     "name": "updatePreset",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "tokenIndex",
           "type": "uint256"
        },
        {
           "internalType": "bool",
           "name": "isActive",
           "type": "bool"
        }
     ],
     "name": "updateTokenActivate",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "tokenIndex",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "newRate",
           "type": "uint256"
        }
     ],
     "name": "updateTokenStablePrice",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  },
  {
     "inputs": [
        {
           "internalType": "uint256",
           "name": "_index",
           "type": "uint256"
        },
        {
           "internalType": "uint256",
           "name": "_amount",
           "type": "uint256"
        }
     ],
     "name": "withdrawToken",
     "outputs": [],
     "stateMutability": "nonpayable",
     "type": "function"
  }
]
const evolveFactoryContract = new web3Instance.eth.Contract(
  ABI,
  factoryContractAddress
);

const addNewPreset = async (
  matchPrice,
  numberOfTeamMemebr,
  createAt,
  rakeAmountInUSD
  // _supportedTokens
) => {
  const [account] = await window.ethereum?.request({ method: "eth_accounts" });
  const addNewPresetMethod = evolveFactoryContract.methods.addNewPreset(
    matchPrice, // entry fee in usd
    numberOfTeamMemebr,
    createAt,
    rakeAmountInUSD
    // _supportedTokens
  );
  const transactionObject = {
    from: web3Instance.utils.toChecksumAddress(account),
    to: web3Instance.utils.toChecksumAddress(factoryContractAddress),
  };
  return new Promise((resolve, reject) => {
    addNewPresetMethod
      .send(transactionObject)
      .on("receipt", (result) => {
        resolve(result);
        console.log("result", result);
      })
      .on("error", (error) => {
        console.log("Error: ", error);
        reject(error);
      });
  });
};

const updatePreset = async (
  presetId,
  entryFeeInUSD,
  numberOfTeamMember,
  createAt,
  rakeAmountInUSD,
  isActive
) => {
  const [account] = await window.ethereum?.request({ method: "eth_accounts" });
  const updatePresetMethod = evolveFactoryContract.methods.updatePreset(
    presetId,
    entryFeeInUSD,
    numberOfTeamMember,
    createAt,
    rakeAmountInUSD,
    isActive
  );
  console.log("updatePresetMethod", updatePresetMethod);
  const transactionObject = {
    from: web3Instance.utils.toChecksumAddress(account),
    to: web3Instance.utils.toChecksumAddress(factoryContractAddress),
  };
  return new Promise((resolve, reject) => {
    updatePresetMethod
      .send(transactionObject)
      .on("receipt", (result) => {
        resolve(result);
        console.log("result", result);
      })
      .on("error", (error) => {
        console.log(error);
        reject(error);
      });
  });
};

const updateTokenRate = async (newRate) => {
  const [account] = await window.ethereum?.request({ method: "eth_accounts" });
  const updateTokenRateMethod = evolveFactoryContract.methods.updateTokenRate(
    toWei(newRate)
  );
  const transactionObject = {
    from: Web3.utils.toChecksumAddress(account),
    to: Web3.utils.toChecksumAddress(factoryContractAddress),
  };
  return new Promise((resolve, reject) => {
    updateTokenRateMethod
      .send(transactionObject)
      .on("receipt", (result) => {
        resolve(result);
        console.log("result", result);
      })
      .on("error", (error) => {
        console.log(error);
        reject(error);
      });
  });
};

export { updateTokenRate, evolveFactoryContract, addNewPreset, updatePreset };
