import React from "react";
import { Create, SimpleForm, NumberInput, TextInput } from "react-admin";
import { MetamaskInteraction } from "../../components/metamask/MetamaskInteraction";
import { useNotify } from "react-admin";
import { lockTokens } from "../../contracts";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  addressInput: {
    minWidth: "450px",
  },
});

const AirdropVestingList = (props) => {
  const notify = useNotify();

  async function saveHandler(params) {
    try {
      await lockTokens(params.accounts, params.amount, params.duration);
      notify("Lock tokens successfully");
    } catch (error) {
      notify(`Error: ${error.message}`);
      console.log(error);
    }
  }

  return (
    <Create {...props}>
      <MetamaskInteraction>
        <SimpleForm save={saveHandler}>
          <CreateDetail />
        </SimpleForm>
      </MetamaskInteraction>
    </Create>
  );
};

const CreateDetail = () => {
  const classes = useStyles();

  return (
    <section className="wrapper">
      <div>
        <h1>Amount (EVOLVE)</h1>
        <NumberInput source="amount" label="" defaultValue={1} />
      </div>
      <div>
        <h1>Duration (day)</h1>
        <NumberInput source="duration" label="" defaultValue={1} />
      </div>
      <div>
        <h1>addresses: address1, address2, ... </h1>
        <TextInput
          placeholder="0x00000000000000000000000000000000000000000,
                     0x00000000000000000000000000000000000000000"
          className={classes.addressInput}
          multiline
          minRows={5}
          source="accounts"
          label=""
          initialValue=""
        />
      </div>
    </section>
  );
};

export { AirdropVestingList };
