import React, { useState, useEffect } from 'react'
import ReactHighcharts from 'react-highcharts/ReactHighstock.src'
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";

function KYCChart(props) {
  const [duration, setDuration] = useState()
  const [formatedFetchedData, setFormatedFetchedData] = useState()
  const { fetchedKycData, defaultDuration } = props

  useEffect(() => {
    const data = []
    if (fetchedKycData && fetchedKycData.length > 0) {
      const from = fetchedKycData[0].groupStartTime
      const end = fetchedKycData[fetchedKycData.length - 1].groupStartTime
      var index = from
      while (index <= end) {
        // eslint-disable-next-line
        const dataPoint = fetchedKycData.find(el => el?.groupStartTime === index)
        if (dataPoint) {
          data.push([dataPoint.groupStartTime, dataPoint.count])
        } else {
          data.push([index, 0])
        }
        index += defaultDuration
      }
      setFormatedFetchedData(data)
    }
    setDuration(defaultDuration)
    // eslint-disable-next-line 
  }, [fetchedKycData])

  useEffect(() => {
    props.durationToParent(duration)
    // eslint-disable-next-line 
  }, [duration])





  const config = {
    yAxis: [{
      offset: 20,
      labels: {
        formatter: function () {
          return this.value
        }
        ,
        x: -15,
        style: {
          "color": "#000", "position": "absolute",
        },
        align: 'left'
      },
    },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        return this.y + '  KYC </b><br/>' + moment(this.x).format('MMMM Do YYYY, h:mm')
      }
    },
    plotOptions: {
      series: {
        showInNavigator: true,
        gapSize: 6,
      }
    },
    title: {
      // text: `KYC statistics`
    },
    chart: {
      height: 600,
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    xAxis: {
      type: 'date',
    },
    rangeSelector: {
      buttons: [
        {
          // type: 'hour',
          text: 'Per minute',
          events: {
            click: () => {
              setDuration(60 * 1000)
            }
          },
        }, {
          // type: 'day',
          // count: 7,
          text: 'Per day',
          events: {
            click: () => {
              setDuration(60 * 60 * 1000 * 24)
            }
          },
        },
        {
          type: 'all',
          text: 'All',
        }
      ],
      selected: 1,
      inputEnabled: false,
      buttonTheme: {
        width: 80,
      },
    },
    series: [{
      name: 'KYC per time',
      type: 'line',
      data: formatedFetchedData ? formatedFetchedData : '',
      tooltip: {
        valueDecimals: 2
      },

    }
    ]
  };
  return (
    <div className='margin-top-1'>
      <ReactHighcharts config={config}></ReactHighcharts>
    </div>
  )
}

export { KYCChart }