import React from "react";
import { useNotify } from "react-admin";
import Switch from "@material-ui/core/Switch";
import Parse from "parse";

const UpdateSwitchBtn = (props) => {
  const notify = useNotify();
  const { record, field } = props;

  const [state, setState] = React.useState({
    switchField: record?.[field],
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    const query = new Parse.Query(props.resource);
    query
      .get(record.id)
      .then((res) => {
        res.set(field, event.target.checked);
        res
          .save()
          .then((response) => {
            notify(`Changes saved`);
          })
          .catch((err) => {
            notify(`Error: ${err.message}`, { type: "error" });
            setState({ ...state, [event.target.name]: !event.target.checked });
            console.log("err", err);
          });
      })
      .catch((err) => {
        notify(`Error: ${err.message}`, { type: "error" });
        setState({ ...state, [event.target.name]: !event.target.checked });
        console.log("err", err);
      });
  };

  return (
    <Switch
      checked={state.switchField}
      onChange={handleChange}
      color="primary"
      name="switchField"
      inputProps={{ "aria-label": "primary checkbox" }}
    />
  );
};

export { UpdateSwitchBtn };
