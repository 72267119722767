import React, { useEffect, useState } from 'react'
import { Metamask } from './Metamask'

function MetamaskInteraction(props) {
  const { ethereum } = window;
  // const [isMetaMaskExists] = useState(MetaMaskOnboarding.isMetaMaskInstalled())
  const [ready, setReady] = useState(false)
  const [changedChainId, setChangedChainId] = useState('')

  useEffect(() => {
    recognizeChainChange()
    //eslint-disable-next-line
  }, []);


  // useEffect(() => {
  // }, [ready])

  const recognizeChainChange = async () => {
    try {
      ethereum?.on('chainChanged', async () => {
        const currentChainId = await ethereum?.request({ method: 'eth_chainId' })
        await setChangedChainId(currentChainId)
        setReady(false)
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>   {
      !ready ?
        <Metamask
          ready={(readyStatus) => setReady(readyStatus)}
          changedChainId={changedChainId}
        /> :
        <> {props.children}   </>
    }
    </>
  )
}


export { MetamaskInteraction }